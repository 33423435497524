import { createContext, useContext } from "react";
import { PlanProviderDataMapping } from "./PlanProvider.hooks";
import { ImportOfData, OnboardingType, PlanContext, PlanType, ProcessingActivityTemplates, SupportChannel, SystemTemplates } from "./PlanProvider.types";
import { DotLegalFullScreenSpinner } from "@dotlegal/dotlegal-ui-components";
import React from "react";

export interface IPlanProvider {
    children: JSX.Element;
}

function PlanProvider(props: IPlanProvider) {
    const { data, isLoading } = PlanProviderDataMapping();

    return (
        <PlanContextContext.Provider value={data}>
            {isLoading ? <DotLegalFullScreenSpinner /> : <React.Fragment>{props.children}</React.Fragment>}
        </PlanContextContext.Provider>
    );
}

export function usePlanContext(): PlanContext {
    return useContext(PlanContextContext)!;
}

export const PlanContextContext = createContext<PlanContext | undefined>({
    planType: PlanType.GdprStarter,
    customListsAccess: false,
    hasTagAccess: false,
    unitsPlan: {
        customListExport: false,
        customLists: false,
        retentionOfDataProcessingAgreement: false,
        standardListExport: false,
        maxNumberOfGroupEntities: undefined,
        contacts: false,
        companyAreas: false,
    },
    technicalPlan: {
        maxNumberOfUsers: undefined,
        auditLog: false,
        azureSSO: false,
        customMasterData: false,
        customRolesAndPermissions: false,
        emailNotifications: false,
        notificationCenter: false,
        userAdministration: false,
        transferUserResponsibility: false,
        fullDataExport: false,
    },
    taskManagementAndAnnualWheelPlan: {
        addToOutlook: false,
        annualWheelReport: false,
        createOwnAnnualWheelActivities: false,
        emailNotifications: false,
        gdprPlanningWheel: false,
        processingActivityValidation: false,
        taskManagement: false,
        maxNumberOfActivities: undefined,
        maxNumberOfStandardControlsInAnnualWheel: undefined,
        niS2Framework: false,
        annualWheelAreas: false,
        relatedActivitiesAndTasks: false,
        iso27001Framework: false,
        iso27002Framework: false,
    },
    systemPlan: {
        automaticRegistrationOfDataProcessorTransfers: false,
        customListExport: false,
        customLists: false,
        linkSystemsAndProcessingActivities: false,
        standardLists: false,
        standardListsExport: false,
        templates: SystemTemplates.MostPopular,
        maxNumberOfSystems: undefined,
        systemStatus: false,
        storageType: false,
        unitType: false,
        dataTypes: false,
        operationalCriticalSystem: false,
        certifications: false,
        documents: false,
        unitTypeFilter: false,
        associatedAssets: false,
        internalControlProcedures: false,
        personalData: false,
        aI: false,
    },
    supportPlan: {
        importOfData: ImportOfData.ContactForInfo,
        onboardingType: OnboardingType.InApp,
        supportChannel: SupportChannel.Mail,
    },
    riskAndClassificationPlan: {
        automaticClassificationOfProcessingActivities: false,
        consequenceCatalog: false,
        createOwnRiskScenarios: false,
        gdprRiskAssessments: false,
        riskClassificationOfDataProcessors: false,
        riskReport: false,
        maxNumberOfTemplates: undefined,
        systemRiskAssessments: false,
        systemRiskHandling: false,
        niS2RiskTemplates: false,
        riskVersions: false,
    },
    processingActivityAndRecordsPlan: {
        caseHandlerModule: false,
        commentsModule: false,
        customListExport: false,
        customLists: false,
        processingActivityStatus: false,
        recordDataProcessor: false,
        recordsExport: false,
        registerSelfAsDataProcessor: false,
        standardListExport: false,
        templates: ProcessingActivityTemplates.MostPopular,
        validationFlow: false,
        maxNumberOfActivities: undefined,
        sharedResponsibility: false,
    },
    proceduresAndPoliciesPlan: {
        maxNumberOfPoliciesAndProcedures: undefined,
        processingActivityAndPolicyLink: false,
    },
    incidentLogPlan: {
        gdprCanSetPermissionsOnRole: false,
        gdprMaxNumberOfIncidents: undefined,
    },
    vendorManagementPlan: {
        hasAccess: false,
        legalEntityClassification: false,
        processingActivityAssociateInternalAssessments: false,
        systemAssociateInternalAssessments: false,
    },
    declarationPlan: {
        hasAccess: false,
        maxNumberOfDeclaration: undefined,
    },
});

export default PlanProvider;
