import { useQuery, useQueryClient } from "react-query";
import { get } from "../../common/api/apiShared";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateLocalStorage, useStateSessionStorage } from "@dotlegal/dotlegal-ui-components";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { useTranslation } from "../../localization/useTranslation";
import { SystemListOverviewRowViewModel, SystemOverViewModel } from "./Systems.types";
import CustomSystemSvg from "./customSystem.svg?url";
import { useState } from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { IsSystemLimitReached } from "../../auth/planProvider/PlanProvider.hooks";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { getEnumValues } from "../../common/enumOperations";
import { SystemEntityType } from "../systemEdit/systemDataAndStorageStep/SystemDataAndStorageStep.types";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

export const onlyUserSpecificSystemStorageKey = "systemsUserSpecific";
export function useSystemDataMapping() {
    const { translateString } = useTranslation();
    const queryClient = useQueryClient();
    const { permissions } = useUserContext();
    const { systemPlan } = usePlanContext();

    const { riskAndClassificationPlan } = usePlanContext();

    const [showDeleteDialog, setShowDeleteDialog] = useState({
        showDialog: false,
        systemId: "",
        systemName: "",
    });
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showAddSystemTemplate, setShowAddSystemTemplate] = useState(false);
    const [showExistingCompaniesDialog, setShowExistingCompaniesDialog] = useState({ showDialog: false, companies: [] as Array<string> });
    const [onlyUserSpecific, setOnlyUserSpecific] = useStateSessionStorage(onlyUserSpecificSystemStorageKey, !permissions.canAccessAllData);
    const [searchedBusinessAreas, setSearchedBusinessAreas] = useStateUrlParamsArray("businessAreas", []);
    const [searchedDataCategories, setSearchedDataCategories] = useStateUrlParamsArray("dataCategories", []);
    const [searchedDataSubjects, setSearchedDataSubjects] = useStateUrlParamsArray("dataSubjects", []);
    const [searchedSystemEntityType, setSearchedSystemEntityType] = useStateUrlParamsArray("entityType", []);

    const queryPart = `?showAllSystems=${!onlyUserSpecific}`;
    const url = `/SystemListOverview${queryPart}`;
    let { isLoading, data, refetch } = useQuery(url, () => get<SystemOverViewModel>(url));

    const riskScoreUrl = `/SystemListOverview/RiskAssessmentScore${queryPart}`;
    let riskScoresQuery = useQuery(riskScoreUrl, () => get<Array<SystemListOverviewRowViewModel>>(riskScoreUrl), {
        enabled:
            data !== undefined &&
            riskAndClassificationPlan !== undefined &&
            riskAndClassificationPlan !== null &&
            riskAndClassificationPlan.systemRiskAssessments &&
            permissions.canManageRiskAssessment,
    });

    let systemLimitReached = IsSystemLimitReached();

    let businessAreaFilterOptions: Array<DotLegalSelectOption> = [];
    let dataCategoriesFilterOptions: Array<DotLegalSelectOption> = [];
    let dataSubjectsFilterOptions: Array<DotLegalSelectOption> = [];
    let systemEntityTypeFilterOptions: Array<DotLegalSelectOption> = [];
    let dataSystem: SystemListOverviewRowViewModel[];
    let systems;
    let hasData = isLoading ? true : data!.systems.length > 0;

    if (data) {
        dataSystem = data.systems.map((system) => {
            return {
                ...system,
                icon: CustomSystemSvg,
            };
        });

        createBusinessAreaSelectOptions(data.businessAreaItems);
        createDataCategorySelectOptions(data.dataCategoryItems);
        createDataSubjectsSelectOptions(data.dataSubjectItems);
        createSystemEntityTypeOptions(data.systems);

        systems = getFilteredRows(dataSystem);
    }

    if (riskScoresQuery.data) {
        var riskAndProgress = riskScoresQuery.data;

        riskAndProgress.forEach((x) => {
            var foundSystem = dataSystem!.find((r) => r.id === x.id);

            if (foundSystem) {
                foundSystem!.riskAssessmentTotalScore = x.riskAssessmentTotalScore;
                foundSystem!.currentRiskAssessmentVersionId = x.currentRiskAssessmentVersionId;
            }
        });
    }

    function getFilteredRows(overviewRows: Array<SystemListOverviewRowViewModel>) {
        let tempData = [...overviewRows];
        if (searchedBusinessAreas.length > 0 && searchedBusinessAreas[0] !== "") {
            tempData = tempData.filter((row) => row.businessAreas.filter((x) => searchedBusinessAreas.indexOf(x.id) >= 0).length > 0);
        }

        if (searchedDataCategories.length > 0 && searchedDataCategories[0] !== "") {
            tempData = tempData.filter((row) => row.dataCategories.filter((x) => searchedDataCategories.indexOf(x.id) >= 0).length > 0);
        }

        if (searchedDataSubjects.length > 0 && searchedDataSubjects[0] !== "") {
            tempData = tempData.filter((row) => row.dataSubjects.filter((x) => searchedDataSubjects.indexOf(x.id) >= 0).length > 0);
        }

        if (systemPlan.unitTypeFilter && searchedSystemEntityType.length > 0 && searchedSystemEntityType[0] !== "") {
            tempData = tempData.filter((row) => searchedSystemEntityType.indexOf(row.systemEntityType?.toString() ?? "") >= 0);
        }

        return tempData;
    }

    function createBusinessAreaSelectOptions(businessAreas: Array<DotLegalSelectOption>) {
        businessAreas.forEach((x) => {
            if (!businessAreaFilterOptions.some((b) => b.id === x.id)) {
                businessAreaFilterOptions.push({ name: x.name, id: x.id });
            }
        });
    }

    function createDataCategorySelectOptions(dataCategories: Array<DotLegalSelectOption>) {
        dataCategories.forEach((x) => {
            if (!dataCategoriesFilterOptions.some((b) => b.id === x.id)) {
                dataCategoriesFilterOptions.push({ name: x.name, id: x.id });
            }
        });
    }

    function createDataSubjectsSelectOptions(dataSubjects: Array<DotLegalSelectOption>) {
        dataSubjects.forEach((x) => {
            if (!dataSubjectsFilterOptions.some((b) => b.id === x.id)) {
                dataSubjectsFilterOptions.push({ name: x.name, id: x.id });
            }
        });
    }

    function createSystemEntityTypeOptions(systems: Array<SystemListOverviewRowViewModel>) {
        return getEnumValues(SystemEntityType).forEach((x) => {
            var name = translateString(setFirstLetterToLowerCase(SystemEntityType[x].toString()));
            if (!systemEntityTypeFilterOptions.some((s) => s.id === x.toString())) {
                var systemEntityTypeFilterOption = systems.map((d) => d.systemEntityType?.toString()).find((t) => t === x.toString());
                if (systemEntityTypeFilterOption) {
                    systemEntityTypeFilterOptions.push({ name: name, id: x.toString() });
                }
            }
        });
    }

    const breadCrumbs = [{ name: translateString("systems") }];

    function refetchSystems() {
        queryClient.fetchQuery(url);
    }

    return {
        breadCrumbs,
        onlyUserSpecific,
        setOnlyUserSpecific,
        systems,
        isLoading,
        businessAreaFilterOptions,
        dataCategoriesFilterOptions,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        searchedDataCategories,
        setSearchedDataCategories,
        hasData,
        showCreateDialog,
        setShowCreateDialog,
        showAddSystemTemplate,
        setShowAddSystemTemplate,
        showExistingCompaniesDialog,
        setShowExistingCompaniesDialog,
        refetchSystems,
        searchedDataSubjects,
        setSearchedDataSubjects,
        dataSubjectsFilterOptions,
        systemLimitReached,
        searchedSystemEntityType,
        setSearchedSystemEntityType,
        systemEntityTypeFilterOptions,
        riskScoresLoading: riskScoresQuery.isLoading,
        showDeleteDialog,
        setShowDeleteDialog,
        refetch,
    };
}
