import React from "react";
import { useLatestAuditsSubmittedWidget } from "./LatestAuditsSubmittedWidget.hooks";
import Widget from "../Widget";
import { Box, styled } from "@mui/material";
import { DotLegalEmptyState, DotLegalHeader, DotLegalSwitch } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import NavigateTo from "../navigateTo/NavigateTo";
import LatestAssessmentsTable from "./latestAssessmentsTable/LatestAssessmentsTable";
import LatestAuditsTable from "./latestAuditsTable/LatestAuditsTable";
import { useUrlProvider } from "../../useUrlProvider";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import LatestAuditsEmptyStateIcon from "./latest-audits-empty-state.svg?react";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import { Trans } from "react-i18next";
import { WidgetLock } from "../WidgetLock.tsx";
import WhiteDashboardBox from "../WhiteDashboardBox.tsx";

export interface LatestAuditsSubmittedWidgetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
}

function LatestAuditsSubmittedWidget(props: LatestAuditsSubmittedWidgetProps) {
    const { permissions } = useUserContext();
    const { translateString } = useTranslation();
    const { getDotLegalVendorMangementInfoUrl } = useUrlProvider();
    const {
        isLoading,
        latestAudits,
        latestAssessments,
        hasVendorManagement,
        isAuditsEnabled,
        isInternalAssessmentsEnabled,
        showInternalAssessments,
        setShowInternalAssessments,
        disableSwitch,
    } = useLatestAuditsSubmittedWidget(props);

    const { getInternalAssessmentUrl, getAuditGroupsUrl } = useUrlProvider();

    const getNavigateTo = () => {
        if (showInternalAssessments && isInternalAssessmentsEnabled) {
            return <NavigateTo color="purple" text={translateString("goToAssessments")} to={getInternalAssessmentUrl()} />;
        }

        if (!showInternalAssessments && isAuditsEnabled && permissions.canManageAuditGroups)
            return <NavigateTo color="purple" text={translateString("goToExternalAudits")} to={getAuditGroupsUrl()} />;

        if (!hasVendorManagement) {
            return <NavigateTo color="purple" text={translateString("unlockVendorManagement")} to={getDotLegalVendorMangementInfoUrl()} />;
        }

        return undefined;
    };

    return (
        <Widget disabled={hasVendorManagement && !isAuditsEnabled && !isInternalAssessmentsEnabled}>
            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                    {showInternalAssessments
                        ? translateString("latestAssessmentSubmissions").toUpperCase()
                        : translateString("latestAuditSubmissions").toUpperCase()}
                </DotLegalHeader>

                {hasVendorManagement && (
                    <DotLegalSwitch
                        onChange={() => setShowInternalAssessments(!showInternalAssessments)}
                        disabled={disableSwitch}
                        checked={showInternalAssessments}
                        leftLabel={
                            <Box component={"span"}>
                                {!isAuditsEnabled && <WidgetLock />} {translateString("external")}
                            </Box>
                        }
                        rightLabel={
                            <Box component={"span"}>
                                {translateString("internal")} {!isInternalAssessmentsEnabled && <WidgetLock />}
                            </Box>
                        }
                    />
                )}

                <WhiteDashboardBox isLoading={isLoading} marginTop={1} fullHeight>
                    {hasVendorManagement ? (
                        showInternalAssessments ? (
                            <LatestAssessmentsTable data={latestAssessments} isEnabled={isInternalAssessmentsEnabled} />
                        ) : (
                            <LatestAuditsTable data={latestAudits} isEnabled={isAuditsEnabled} />
                        )
                    ) : (
                        <DotLegalEmptyState
                            textColor={"darkBlue"}
                            noFixedHeight
                            noPadding
                            icon={<LatestAuditsEmptyStateIcon />}
                            text={
                                <Trans
                                    i18nKey={"upgradePrivacyForAccessToVendorManagement"}
                                    components={{
                                        box: (
                                            <DotLegalLink to={getDotLegalVendorMangementInfoUrl()} linkColor="primary">
                                                {undefined}
                                            </DotLegalLink>
                                        ),
                                    }}
                                />
                            }
                        />
                    )}
                </WhiteDashboardBox>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>{getNavigateTo()}</Box>
            </Box>
        </Widget>
    );
}
export default LatestAuditsSubmittedWidget;
