import {
    useCertificationsQuery,
    useCompaniesQuery,
    useCountriesQuery,
    useDataCategoriesQuery,
    useDataSubjectsQuery,
    useDocumentQuery,
    useGroupCompaniesQuery,
    usePoliciesQuery,
    useSecurityMeasuresQuery,
    useSelectableAllLegalBasesAllQuery,
    useSelectableBusinessAreaQuery,
    useSelectableDeletionPeriodsQuery,
    useSelectableProcessingActivitiesQuery,
    useSelectablePurposesQuery,
    useSelectableSystemsQuery,
    useSelectableTagsQuery,
    useSelectableUsersQuery,
    useSystemDocumentQuery,
    useTransferBasesQuery,
} from "../../common/hooks/useSelectableItemQueries";
import { DataSourceEnum, SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { useTranslation } from "../../localization/useTranslation";
import { ControllerProcess, SharingTypeEnum } from "../../processingActivity/processingActivityOverview/ProcessingActivityOverview.types";
import { SystemType } from "../../processingActivity/systemsStep/SystemsStep.types";
import { LegalEntityRiskAssessment } from "../../legalEntity/legalEntityRiskQuestionnaire/LegalEntityRiskQuestionnaire.types";
import { AnnualWheelPriority } from "../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import { LegalEntityType, LevelOfProtectionType } from "../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { LegalBasisSelectOption } from "../../processingActivity/legalBasesStep/LegalBasesStep.types";
import { getEnumValues } from "../../common/enumOperations";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import { ProcessingActivityStatus } from "../../processingActivity/processingActivities/ProcessingActivities.types";
import { PropertyType } from "../../common/PropertyTypes";

import { DotLegalDocumentType } from "../../documents/Documents.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { SystemStatus } from "../../system/systemEdit/SystemEdit.types";
import { dataTypeOptions, entityTypeOptions, storageTypeOptions } from "../../system/systemEdit/systemDataAndStorageStep/SystemDataAndStorageStep.hooks";
import {
    legalEntityAuditFrequencyOptions,
    legalEntityAuditTypeOptions,
} from "../../legalEntity/legalEntityManagement/steps/legalEntityAuditStep/legalEntityAuditStep.hooks";
import { getNoOfDataSubjectSelectOptions, getNoOfProcessorsSelectOptions } from "../../processingActivity/dataSubjectStep/DataSubjectStep.hooks";

export function useColumnTypeContextQueryProvider(type: PropertyType) {
    const {
        translateString,
        translateLegalEntityRiskAssessment,
        translateCountry,
        translateStatus,
        translateDocumentType,
        translateDataSubjectsEnum,
        translateProcessorsEnum,
    } = useTranslation();
    const { processingActivityAndRecordsPlan, hasTagAccess } = usePlanContext();

    let purposeQuery = useSelectablePurposesQuery(type === PropertyType.Purposes);
    let deletionPeriodsQuery = useSelectableDeletionPeriodsQuery(type === PropertyType.DeletionPeriods);
    let processingActivityQuery = useSelectableProcessingActivitiesQuery(type === PropertyType.ProcessingActivities);
    let systemsQuery = useSelectableSystemsQuery(type === PropertyType.Systems);
    let businessAreasQuery = useSelectableBusinessAreaQuery(type === PropertyType.BusinessAreas);
    let responsiblesQuery = useSelectableUsersQuery(enableUserQuery(type));
    let dataCategoriesQuery = useDataCategoriesQuery(type === PropertyType.DataCategories);
    let dataSubjectsQuery = useDataSubjectsQuery(type === PropertyType.DataSubjects);
    let transferBasesQuery = useTransferBasesQuery(type === PropertyType.TransferBases);
    let securityMeasuresQuery = useSecurityMeasuresQuery(type === PropertyType.SecurityMeasures, true);
    let companiesQuery = useCompaniesQuery(enableCompanyQuery(type));
    let groupCompaniesQuery = useGroupCompaniesQuery(enableGroupCompanyQuery(type));
    let documentQuery = useDocumentQuery(enableDocumentQuery(type));
    let countriesQuery = useCountriesQuery(enableCountryQuery(type));
    let allLegalBasesQuery = useSelectableAllLegalBasesAllQuery(enableLegalBasisQuery(type));
    let policiesQuery = usePoliciesQuery(type === PropertyType.Policies);
    let systemDocumentsQuery = useSystemDocumentQuery(type === PropertyType.SystemDocuments);
    let certificationsQuery = useCertificationsQuery(type === PropertyType.Certifications);
    let tagsQuery = useSelectableTagsQuery(hasTagAccess);

    const getListOptions = () => {
        if (type === PropertyType.Purposes) return purposeQuery;
        else if (type === PropertyType.DeletionPeriods) return deletionPeriodsQuery;
        else if (type === PropertyType.LegalBases)
            return { data: getLegalBases(allLegalBasesQuery.data?.directives, allLegalBasesQuery.data?.doubleLegalBases, allLegalBasesQuery.isLoading) };
        else if (type === PropertyType.ProcessingActivities) return processingActivityQuery;
        else if (type === PropertyType.Systems) return systemsQuery;
        else if (type === PropertyType.BusinessAreas) return businessAreasQuery;
        else if (enableUserQuery(type)) return responsiblesQuery;
        else if (type === PropertyType.DataCategories) return dataCategoriesQuery;
        else if (type === PropertyType.DataSubjects) return dataSubjectsQuery;
        else if (type === PropertyType.DisclosureNationalLaws || type === PropertyType.NationalLaws)
            return { data: allLegalBasesQuery.data?.nationalLaws, isLoading: allLegalBasesQuery.isLoading };
        else if (type === PropertyType.TransferBases) return transferBasesQuery;
        else if (type === PropertyType.SecurityMeasures) return securityMeasuresQuery;
        else if (enableCompanyQuery(type)) return companiesQuery;
        else if (enableGroupCompanyQuery(type)) return groupCompaniesQuery;
        else if (type === PropertyType.TIA)
            return { data: getDocuments(documentQuery.data?.filter((x) => x.type === DotLegalDocumentType.TIA)), isLoading: documentQuery.isLoading };
        else if (type === PropertyType.Documents) return { data: documentQuery.data, isLoading: documentQuery.isLoading };
        else if (type === PropertyType.DataProcessorAgreement)
            return {
                data: getDocuments(documentQuery.data?.filter((x) => x.type === DotLegalDocumentType.DataProcessorAgreement)),
                isLoading: documentQuery.isLoading,
            };
        else if (enableCountryQuery(type)) {
            return {
                data: countriesQuery.data?.map((x) => {
                    return { name: translateCountry(x.countryCode), id: x.countryCode };
                }),
                isLoading: countriesQuery.isLoading,
            };
        } else if (type === PropertyType.SharingTypes) return { data: getSharingTypes(translateString), isLoading: false };
        else if (type === PropertyType.SystemTypes) return { data: getSystemTypes(translateString), isLoading: false };
        else if (type === PropertyType.LegalEntityRisk)
            return { data: getLegalEntityRiskAssessment(translateLegalEntityRiskAssessment, translateString), isLoading: false };
        else if (type === PropertyType.RecipientRoles || type === PropertyType.SenderRoles || type === PropertyType.Roles)
            return { data: getSharingRoles(type, translateString), isLoading: false };
        else if (type === PropertyType.Risk || type === PropertyType.ProcessingActivityClassification)
            return { data: getProcessingActivityRisks(type, translateString), isLoading: false };
        else if (type === PropertyType.ReceivingLocation || type === PropertyType.DataProcessorLocations || type === PropertyType.Location)
            return { data: getLocations(translateString), isLoading: false };
        else if (type === PropertyType.DisclosureLegalBases) return { data: allLegalBasesQuery.data?.directives, isLoading: allLegalBasesQuery.isLoading };
        else if (type === PropertyType.DisclosureDoubleLegalBases)
            return { data: allLegalBasesQuery.data?.doubleLegalBases, isLoading: allLegalBasesQuery.isLoading };
        else if (type === PropertyType.Agreement)
            return {
                data: getDocuments(documentQuery.data?.filter((x) => x.type === DotLegalDocumentType.OtherAgreements)),
                isLoading: documentQuery.isLoading,
            };
        else if (type === PropertyType.Type) {
            return { data: getLegalEntityTypes(translateString), isLoading: false };
        } else if (type === PropertyType.ProcessingActivityStatus)
            return { data: getStatusTypes(translateStatus, processingActivityAndRecordsPlan.caseHandlerModule), isLoading: false };
        else if (type === PropertyType.DocumentTypes) return { data: getDocumentTypes(translateDocumentType), isLoading: false };
        else if (type === PropertyType.LegalEntityAuditType) return { data: legalEntityAuditTypeOptions(translateString), isLoading: false };
        else if (type === PropertyType.LegalEntityAuditFrequency) return { data: legalEntityAuditFrequencyOptions(translateString), isLoading: false };
        else if (type === PropertyType.Policies) return { data: policiesQuery.data, isLoading: policiesQuery.isLoading };
        else if (type === PropertyType.SystemStatus) return { data: getSystemStatusTypes(translateString), isLoading: false };
        else if (type === PropertyType.SystemDocuments) return { data: systemDocumentsQuery.data, isLoading: systemDocumentsQuery.isLoading };
        else if (type === PropertyType.SystemStorageType) return { data: storageTypeOptions(translateString), isLoading: false };
        else if (type === PropertyType.SystemEntityType) return { data: entityTypeOptions(translateString), isLoading: false };
        else if (type === PropertyType.SystemDataType) return { data: dataTypeOptions(translateString), isLoading: false };
        else if (type === PropertyType.Certifications) return { data: certificationsQuery.data, isLoading: certificationsQuery.isLoading };
        else if (type === PropertyType.DataSource) return { data: getDataSources(translateString) };
        else if (type === PropertyType.NumberOfDataSubjects) return { data: getNoOfDataSubjectSelectOptions(translateDataSubjectsEnum) };
        else if (type === PropertyType.NumberOfProcessors) return { data: getNoOfProcessorsSelectOptions(translateProcessorsEnum) };
        else if (type === PropertyType.Tags) return tagsQuery;
    };

    return {
        getListOptions,
    };

    function getDocuments(data: SelectableItem[] | undefined) {
        if (data) {
            data.push({ name: translateString("no"), id: "False" });
            data.push({ name: translateString("yes"), id: "True" });
        }
        return data;
    }

    function getLegalBases(directives: LegalBasisSelectOption[] | undefined, doubleLegalBases: LegalBasisSelectOption[] | undefined, isLoading: boolean) {
        if (!isLoading) {
            return directives!.concat(doubleLegalBases!);
        }

        return [];
    }

    function enableUserQuery(type: PropertyType) {
        return type === PropertyType.Owners || type === PropertyType.ValidatedBy || type === PropertyType.Responsibles || type === PropertyType.DocumentOwners;
    }

    function enableCompanyQuery(type: PropertyType) {
        return (
            type === PropertyType.DataProcessors ||
            type === PropertyType.Senders ||
            type === PropertyType.Recipients ||
            type === PropertyType.Sources ||
            type === PropertyType.Disclosures ||
            type === PropertyType.DataControllers ||
            type === PropertyType.SecondarySuppliers ||
            type === PropertyType.DataProcessorNames ||
            type === PropertyType.LegalEntities ||
            type === PropertyType.JointDataControllers
        );
    }

    function enableGroupCompanyQuery(type: PropertyType) {
        return type === PropertyType.GroupCompanies || type === PropertyType.GroupEntityDataControllers || type === PropertyType.GroupEntitiesOnDocuments;
    }

    function enableCountryQuery(type: PropertyType) {
        return (
            type === PropertyType.RecipientCountries ||
            type === PropertyType.SenderCountries ||
            type === PropertyType.StorageLocations ||
            type === PropertyType.SupportAccessLocations ||
            type === PropertyType.Country ||
            type === PropertyType.DataProcessorCountries
        );
    }

    function enableDocumentQuery(type: PropertyType) {
        return type === PropertyType.DataProcessorAgreement || type === PropertyType.Documents || type === PropertyType.TIA;
    }

    function enableLegalBasisQuery(type: PropertyType) {
        return (
            type === PropertyType.LegalBases ||
            type === PropertyType.DisclosureDoubleLegalBases ||
            type === PropertyType.DisclosureLegalBases ||
            type === PropertyType.DisclosureNationalLaws ||
            type === PropertyType.NationalLaws
        );
    }
}

export function getLegalEntityTypes(translateString: (key: string, interpolation?: any) => string) {
    return getEnumValues(LegalEntityType).map((x) => {
        const name = translateString(setFirstLetterToLowerCase(LegalEntityType[x].toString()));
        return { name: name, id: x.toString() };
    });
}

export function getSharingRoles(type: PropertyType, translateString: (key: string, interpolation?: any) => string) {
    const sharingRoles = [
        { id: ControllerProcess.Controller.toString(), name: translateString("Controller") },
        { id: ControllerProcess.ProcessorW.toString(), name: translateString("ProcessorW") },
        { id: ControllerProcess.JointControllers.toString(), name: translateString("JointControllers") },
    ];

    if (type === PropertyType.RecipientRoles || type === PropertyType.Roles)
        sharingRoles.push({ id: ControllerProcess.Subprocessor.toString(), name: translateString("Subprocessor") });

    return sharingRoles;
}

export function getSharingTypes(translateString: (key: string, interpolation?: any) => string) {
    return [
        { name: translateString("dataProcessors"), id: SharingTypeEnum.dataProcessor.toString() },
        { name: translateString("dataControllers"), id: SharingTypeEnum.dataController.toString() },
        { name: translateString("disclosures"), id: SharingTypeEnum.disclosure.toString() },
        { name: translateString("sources"), id: SharingTypeEnum.source.toString() },
        { name: translateString("JointControllers"), id: SharingTypeEnum.jointDataController.toString() },
    ];
}

export function getStatusTypes(translateStatus: (status: string) => string, hasCaseManagementEnabled: boolean) {
    var status = [
        { name: translateStatus(ProcessingActivityStatus.Active.toString()), id: ProcessingActivityStatus.Active.toString() },
        { name: translateStatus(ProcessingActivityStatus.Draft.toString()), id: ProcessingActivityStatus.Draft.toString() },
        { name: translateStatus(ProcessingActivityStatus.Archived.toString()), id: ProcessingActivityStatus.Archived.toString() },
    ];

    if (hasCaseManagementEnabled) {
        status.push({ name: translateStatus(ProcessingActivityStatus.DraftSubmitted.toString()), id: ProcessingActivityStatus.DraftSubmitted.toString() });
        status.push({
            name: translateStatus(ProcessingActivityStatus.AwaitingResearcher.toString()),
            id: ProcessingActivityStatus.AwaitingResearcher.toString(),
        });
        status.push({ name: translateStatus(ProcessingActivityStatus.Cancelled.toString()), id: ProcessingActivityStatus.Cancelled.toString() });
    }

    return status;
}

export function getLocations(translateString: (key: string, interpolation?: any) => string) {
    return [
        { id: LevelOfProtectionType.EU.toString(), name: translateString("eu") },
        { id: LevelOfProtectionType.EEA.toString(), name: translateString("eea") },
        { id: LevelOfProtectionType.AdequateProtection.toString(), name: translateString("secureThirdCountries") },
        { id: LevelOfProtectionType.ThirdCountry.toString(), name: translateString("unsecureThirdCountries") },
    ];
}

export function getSystemTypes(translateString: (key: string, interpolation?: any) => string) {
    return [
        { id: SystemType.GlobalSystem.toString(), name: translateString("globalSystem") },
        { id: SystemType.LocalSystem.toString(), name: translateString("localSystem") },
        { id: SystemType.PhysicalArchive.toString(), name: translateString("physicalArchive") },
    ];
}

export function getProcessingActivityRisks(type: PropertyType, translateString: (key: string, interpolation?: any) => string) {
    return [
        { id: "0", name: type === PropertyType.ProcessingActivityClassification ? translateString("noData") : translateString("notAnswered") },
        { id: AnnualWheelPriority.VeryLow.toString(), name: translateString("veryLow") },
        { id: AnnualWheelPriority.Low.toString(), name: translateString("low") },
        { id: AnnualWheelPriority.Moderate.toString(), name: translateString("moderate") },
        { id: AnnualWheelPriority.High.toString(), name: translateString("high") },
        { id: AnnualWheelPriority.VeryHigh.toString(), name: translateString("veryHigh") },
    ];
}

export function getSystemStatusTypes(translateString: (key: string, interpolation?: any) => string) {
    return [
        { name: translateString("active"), id: SystemStatus.Active.toString() },
        { name: translateString("underImplementation"), id: SystemStatus.UnderImplementation.toString() },
        { name: translateString("phasedOut"), id: SystemStatus.PhasedOut.toString() },
    ];
}

export function getLegalEntityRiskAssessment(
    translateLegalEntityRiskAssessment: (risk: string) => string,
    translateString: (key: string, interpolation?: any) => string
) {
    let items = Object.keys(LegalEntityRiskAssessment)
        .filter((k) => typeof LegalEntityRiskAssessment[k as any] === "number")
        .map((i, k) => {
            return { name: translateLegalEntityRiskAssessment(k.toString()), id: k.toString() };
        });
    items.push({ name: translateString("labelNone"), id: "none" });
    return items;
}

export function getDocumentTypes(translateDocumentType: (key: string, interpolation?: any) => string) {
    return [
        { id: DotLegalDocumentType.DataProcessorAgreement.toString(), name: translateDocumentType("2") },
        { id: DotLegalDocumentType.Other.toString(), name: translateDocumentType("1") },
        { id: DotLegalDocumentType.TIA.toString(), name: translateDocumentType("3") },
        { id: DotLegalDocumentType.CTA.toString(), name: translateDocumentType("4") },
        { id: DotLegalDocumentType.CooperationAgreement.toString(), name: translateDocumentType("5") },
        { id: DotLegalDocumentType.ServiceAgreement.toString(), name: translateDocumentType("6") },
        { id: DotLegalDocumentType.PhDAgreement.toString(), name: translateDocumentType("7") },
        { id: DotLegalDocumentType.DisclosureAgreement.toString(), name: translateDocumentType("8") },
        { id: DotLegalDocumentType.JointDataControllerAgreement.toString(), name: translateDocumentType("9") },
        { id: DotLegalDocumentType.OtherAgreements.toString(), name: translateDocumentType("10") },
        { id: DotLegalDocumentType.QualityProjects.toString(), name: translateDocumentType("11") },
        { id: DotLegalDocumentType.Oversight.toString(), name: translateDocumentType("12") },
        { id: DotLegalDocumentType.ExceptForDataProcessingAgreement.toString(), name: translateDocumentType("13") },
        { id: DotLegalDocumentType.DataProcessingAgreementAsDataProcessor.toString(), name: translateDocumentType("14") },
        { id: DotLegalDocumentType.ConfidentialityAgreement.toString(), name: translateDocumentType("15") },
        { id: DotLegalDocumentType.SubProcessor.toString(), name: translateDocumentType("16") },
    ];
}

export function getDataSources(translateString: (key: string, interpolation?: any) => string) {
    return [
        { id: DataSourceEnum.TheDataSubject.toString(), name: translateString("theDataSubject") },
        { id: DataSourceEnum.ExternalDataSource.toString(), name: translateString("externalDataSource") },
    ];
}
