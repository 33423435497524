import React from "react";
import { useTagsMultiSelect } from "./TagsMultiSelect.hooks";
import DotLegalMultiSelectWithButton from "../dotLegalSelectWithButton/DotLegalMultiSelectWithButton";
import { useTranslation } from "../../../localization/useTranslation";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import TagDialog from "../../../masterData/tag/tagDialog/TagDialog";
import { TagEntityType } from "./TagsMultiSelect.types";
import { SelectWrapper } from "../../../taskManagement/editTaskDialog/masterDataSection/MasterDataSection";
import { DotLegalChip, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";

export interface TagsMultiSelectProps {
    entityId?: string;
    entityType: TagEntityType;
    customControl?: {
        onChange: (tagIds: Array<string>) => void;
        selectedTags: Array<string>;
    };
    variant?: "standard" | "outlined";
    disabled?: boolean;
    displayOnlyAsChips?: boolean;
}

function TagsMultiSelect(props: TagsMultiSelectProps) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const {
        isLoading,
        selectableTags,
        showCreateTagDialog,
        selectedTags,
        setShowCreateTagDialog,
        onTagCreated,
        onRemoveTagRelation,
        onAddTagRelation,
        onRelationChange,
        parentHandlesMutation,
    } = useTagsMultiSelect(props);

    const getLoadingBox = () => {
        return (
            <SelectWrapper>
                <DotLegalSelect
                    disabled={!permissions.taskPermissions.edit}
                    options={[]}
                    selectedItem={undefined}
                    disableClearable
                    onChange={() => {}}
                    placeholder={""}
                    variant={"standard"}
                    label={"Loading..."}
                    noOptionsLabel={"Loading..."}
                    isLoading={isLoading}
                />
            </SelectWrapper>
        );
    };
    return (
        <>
            {props.displayOnlyAsChips ? (
                isLoading ? (
                    getLoadingBox()
                ) : (
                    <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: 1 }}>
                        {selectableTags
                            .filter((x) => selectedTags.indexOf(x.id) !== -1)
                            .map((tag) => {
                                return (
                                    <Box sx={{ marginRight: 1, marginBottom: 0.5 }} key={tag.id}>
                                        <DotLegalChip isSelected size="small" key={tag.id} color={tag.color} value={tag.name}></DotLegalChip>
                                    </Box>
                                );
                            })}
                    </Box>
                )
            ) : (
                <DotLegalMultiSelectWithButton
                    disabled={props.disabled}
                    isLoading={isLoading}
                    options={selectableTags}
                    chosenOptions={selectedTags}
                    onChange={(tags) => (parentHandlesMutation ? onRelationChange(tags) : {})}
                    label={translateString("tags")}
                    noOptionsLabel={translateString("noOptions")}
                    onButtonClick={() => setShowCreateTagDialog(true)}
                    buttonLabel={translateString("createTag")}
                    hasPermission={permissions.canManageMasterData}
                    onItemAdded={(item) => onAddTagRelation(item)}
                    onItemDeleted={(item) => onRemoveTagRelation(item)}
                    variant={props.variant ?? "outlined"}
                />
            )}
            {showCreateTagDialog && (
                <TagDialog onHideDialog={() => setShowCreateTagDialog(false)} onSave={(tagId) => onTagCreated(tagId)} selectedTag={undefined} />
            )}
        </>
    );
}

export default TagsMultiSelect;
