import { IRiskWidgetProps } from "./RiskWidget.tsx";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared.ts";
import { DashboardRiskOverview } from "./RiskWidget.types.ts";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { usePlanContext } from "../../auth/planProvider/PlanProvider.tsx";

export function useRiskWidget(props: IRiskWidgetProps) {
    const planContext = usePlanContext();
    const userContext = useUserContext();

    const hasProcessingActivityAccess =
        planContext.riskAndClassificationPlan.gdprRiskAssessments &&
        userContext.permissions.processingActivityPermissions.read &&
        userContext.permissions.riskPermissions.read;
    const hasSystemAccess =
        planContext.riskAndClassificationPlan.systemRiskAssessments &&
        userContext.permissions.systemPermissions.read &&
        userContext.permissions.riskPermissions.read;

    const defaultSystemsChosen = hasProcessingActivityAccess ? false : hasSystemAccess;

    const localStorageKey = "dashboard-risk-systems-chosen";
    const storageValue = localStorage.getItem(localStorageKey);
    const [systemsChosen, setSystemsChosen] = useStateLocalStorage(localStorageKey, props.isSystemChosen ?? defaultSystemsChosen);

    if (
        (storageValue === "false" && !hasProcessingActivityAccess) ||
        (!defaultSystemsChosen && !hasProcessingActivityAccess) ||
        (defaultSystemsChosen && !hasSystemAccess) ||
        (storageValue === "true" && !hasSystemAccess)
    ) {
        localStorage.removeItem(localStorageKey);
    }

    const isEnabled = hasProcessingActivityAccess || hasSystemAccess;

    const url = `/ProcessingActivityRiskOverview/dashboard?systemsChosen=${systemsChosen}&onlyUserSpecific=${props.onlyUserSpecific}&forGroupCompany=${props.selectedGroupCompany}`;
    const { isLoading, data } = useQuery(url, () => get<DashboardRiskOverview>(url), { enabled: isEnabled });
    const onPaOrSystemChange = () => {
        setSystemsChosen(!systemsChosen);
    };

    return {
        systemsChosen,
        onPaOrSystemChange,
        isLoading: isLoading,
        data,
        isEnabled,
        systemsOptionEnabled: hasSystemAccess,
        processingActivityOptionEnabled: hasProcessingActivityAccess,
        nis2Enabled: planContext.riskAndClassificationPlan.niS2RiskTemplates,
        hasGdprPlan: planContext.riskAndClassificationPlan.gdprRiskAssessments,
        hasSystemsPlan: planContext.riskAndClassificationPlan.systemRiskAssessments,
    };
}
