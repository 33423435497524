import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../localization/useTranslation";
import ProcessingActivityOverviewDialog from "../../processingActivity/processingActivityOverviewDialog/ProcessingActivityOverviewDialog";
import { useUrlProvider } from "../../useUrlProvider";
import PolicyDocuments from "../policyDocuments/PolicyDocuments";
import { usePolicyDataMapping } from "./PolicyOverview.hooks";
import { usePolicyOverviewStyles } from "./PolicyOverview.styles";
import SystemOverviewDialog from "../../system/systemOverviewDialog/SystemOverviewDialog";
import { DotLegalFixedHeightText, DotLegalHeading, DotLegalPageHeader } from "@dotlegal/dotlegal-ui-components";
import { useResponsibleQuery } from "../../user/hooks/useResponsibleQuery";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";

function PolicyOverview() {
    const { translateString, translatePolicyTypes, translateNumber } = useTranslation();
    const {
        data,
        isLoading,
        setShowProcessingActivityDialog,
        showProcessingActivityDialog,
        hasProcessingActivities,
        showSystemDialog,
        setShowSystemDialog,
        hasSystems,
    } = usePolicyDataMapping();
    const responsibleQuery = useResponsibleQuery();
    const { getPoliciesUrl } = useUrlProvider();
    const styles = usePolicyOverviewStyles(hasProcessingActivities, hasSystems);
    const { permissions, customerName } = useUserContext();
    const { hasTagAccess } = usePlanContext();

    const getNumberValue = (value: number) => {
        return isLoading ? <Skeleton variant={"text"} /> : <Box sx={styles.number}>{translateNumber(value!)}</Box>;
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={[{ name: translateString("policiesAndProcedures"), link: getPoliciesUrl() }, { name: data?.name }]}
            />
            <DotLegalPaper background={"blueWave"} backgroundCover>
                <Grid container>
                    <React.Fragment>
                        <Grid item sm>
                            <DotLegalHeading name={translateString("type")}>
                                <DotLegalFixedHeightText isLoading={isLoading} content={data && translatePolicyTypes(data?.type.toString())} />
                            </DotLegalHeading>
                        </Grid>
                        <Grid item sm>
                            <DotLegalHeading name={translateString("responsible")}>
                                <DotLegalFixedHeightText
                                    isLoading={isLoading}
                                    content={
                                        data && data.responsibleId && responsibleQuery.data(data.responsibleId)?.find((x) => x.id === data.responsibleId)?.name
                                    }
                                />
                            </DotLegalHeading>
                        </Grid>
                        {permissions.processingActivityPermissions.read && (
                            <Grid
                                item
                                sm
                                onClick={() => {
                                    if (hasProcessingActivities) {
                                        setShowProcessingActivityDialog(true);
                                    }
                                }}
                                sx={styles.processingActivityHover}
                            >
                                <DotLegalHeading name={translateString("processingActivities")}>
                                    {getNumberValue((data?.processingActivities.length ?? 0) + (data?.noOfProcessingActivitiesWithNoAccess ?? 0))}
                                </DotLegalHeading>
                            </Grid>
                        )}
                        {permissions.systemPermissions.read && (
                            <Grid
                                item
                                sm
                                onClick={() => {
                                    if (hasSystems) {
                                        setShowSystemDialog(true);
                                    }
                                }}
                                sx={styles.systemHover}
                            >
                                <DotLegalHeading name={translateString("systems")}>
                                    {getNumberValue((data?.systems.length ?? 0) + (data?.noOfSystemsWithNoAccess ?? 0))}
                                </DotLegalHeading>
                            </Grid>
                        )}

                        {hasTagAccess && (
                            <Grid item sm>
                                <DotLegalHeading name={translateString("tags")}>
                                    <DotLegalFixedHeightText isLoading={isLoading} content={data && data.tags.join(", ")} />
                                </DotLegalHeading>
                            </Grid>
                        )}
                    </React.Fragment>
                </Grid>
            </DotLegalPaper>
            <Box marginTop={2}>
                <PolicyDocuments />
            </Box>

            {showProcessingActivityDialog && (
                <ProcessingActivityOverviewDialog
                    onCloseDialog={() => setShowProcessingActivityDialog(false)}
                    processingActivities={data!.processingActivities}
                    header={translateString("policyProcessingActivityDialogDescription")}
                    numberOfProcessingWithoutaccess={data?.noOfProcessingActivitiesWithNoAccess}
                />
            )}

            {showSystemDialog && (
                <SystemOverviewDialog
                    onCloseDialog={() => setShowSystemDialog(false)}
                    systems={data!.systems}
                    header={translateString("policySystemDialogDescription")}
                    numberOfSystemsWithoutaccess={data?.noOfSystemsWithNoAccess}
                />
            )}
        </React.Fragment>
    );
}

export default PolicyOverview;
