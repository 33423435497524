import { ITableHeader, DotLegalTableWithControls, DotLegalMultiSelect, DotLegalOverflowMenu, DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { IconButton, Box, Grid, TableRow, TableCell } from "@mui/material";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import DotLegalNoteDialog from "../../../common/components/dotLegalNoteDialog/DotLegalNoteDialog";
import QuestionnaireEvaluation from "../../../common/components/questionnaireComponents/questionnaireEvaluation/QuestionnaireEvaluation";
import QuestionnaireReportButtons from "../../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportButtons";
import QuestionnaireReportOverflowButtons from "../../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportOverflowButtons";
import { NoteSVG, warning } from "../../../common/icons";
import { useTranslation } from "../../../localization/useTranslation";
import { useRiskIndicator } from "../../../riskAssessment/riskIndicator/RiskIndicator.hooks";
import { BaseViewModel } from "../../../system/systemOverview/SystemOverview.types";
import { useUrlProvider } from "../../../useUrlProvider";
import EmptyState from "../../../common/svgs/emptyState.svg?react";
import QuestionnaireTemplateLink from "../../legalEntityAuditTab/QuestionnaireTemplateLink";
import DeleteClassificationDialog from "../deleteClassificationDialog/DeleteClassificationDialog";
import EvaluateClassificationDialog from "../evaluateClassificationDialog/EvaluateClassificationDialog";
import LegalEntityClassificationDialog from "../legalEntityClassificationDialog/LegalEntityClassificationDialog";
import {
    AssignedLegalEntity,
    LegalEntityClassificationApiModel,
    LegalEntityClassificationApiRow,
    LegalEntityClassificationViewRow,
    RiskLevel,
} from "../LegalEntityClassificationTab.types";
import ReopenClassificationDialog from "../reopenClassificationDialog/ReopenClassificationDialog";
import useClassificationTableHooks from "./ClassificationTable.hooks";

export interface IClassificationTableProps {
    data: LegalEntityClassificationApiModel | undefined;
    isLoading: boolean;
    refetch: () => void;
    paqgeType: "legalEntity" | "system";
    emptyStateText: string;
}

function ClassificationTable(props: IClassificationTableProps) {
    const { translateString, translateDate } = useTranslation();
    const { getSystemOverviewUrl, getLegalEntityClassificationUrl, getLegalEntityUrl } = useUrlProvider();
    const {
        hasData,
        setShowClassificationDialog,
        showClassificationDialog,
        selectedSystems,
        setSelectedSystems,
        classifications,
        onClassificationCreated,
        selectedClassification,
        setSelectedClassification,
        setShowNoteDialog,
        showNoteDialog,
        saveNote,
        isSavingNote,
        setShowDeleteDialog,
        showDeleteDialog,
        onClassificationDeleted,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        showEvaluateDialog,
        setShowEvaluateDialog,
        onClassificationEvaluated,
        setShowReopenDialog,
        showReopenDialog,
        selectedLegalEntities,
        setSelectedLegalEntities,
    } = useClassificationTableHooks(props);

    const { getRiskIcon } = useRiskIndicator();
    const { vendorManagementPlan } = usePlanContext();

    function getMenuItems(row: LegalEntityClassificationApiRow) {
        const legalEntityPermissions = row.permissions;

        let menuItems = [];

        if (legalEntityPermissions.edit) {
            menuItems.push({
                menuItem: translateString("note"),
                onClick: () => {
                    setShowNoteDialog(true);
                    setSelectedClassification(row);
                },
            });
        }

        if (vendorManagementPlan.hasAccess && legalEntityPermissions.delete) {
            menuItems.push({
                menuItem: translateString("deleteClassification"),
                onClick: () => {
                    setSelectedClassification(row);
                    setShowDeleteDialog(true);
                },
            });
        }

        if (row.submitted && legalEntityPermissions.edit) {
            menuItems.push({
                menuItem: translateString("evaluateClassificationHeader"),
                onClick: () => {
                    setSelectedClassification(row);
                    setShowEvaluateDialog(true);
                },
            });
        }

        if (row.submitted && legalEntityPermissions.edit) {
            menuItems.push({
                menuItem: translateString("reopenCompletedClassification"),
                onClick: () => {
                    setSelectedClassification(row);
                    setShowReopenDialog(true);
                },
            });
        }

        return menuItems;
    }

    function formatNote(row: LegalEntityClassificationApiRow) {
        return (
            row.note && (
                <IconButton
                    onClick={() => {
                        setSelectedClassification(row);
                        setShowNoteDialog(true);
                    }}
                >
                    {NoteSVG}
                </IconButton>
            )
        );
    }

    function getSystemsUrl(system?: BaseViewModel) {
        if (system) {
            return formatUrl(system.id, system.name, getSystemOverviewUrl(system.id));
        }

        return undefined;
    }

    function getLegalEntityOverviewUrl(legalEntity: AssignedLegalEntity) {
        return formatUrl(legalEntity.legalEntityId, legalEntity.name, getLegalEntityUrl(legalEntity.legalEntityId));
    }

    function formatUrl(id: string, name: string, url: string) {
        return (
            <Box key={id} component={"span"} sx={{ fontWeight: "bold" }}>
                <DotLegalLink linkColor={"primary"} to={url}>
                    {name}
                </DotLegalLink>
            </Box>
        );
    }

    function getRisk(row: LegalEntityClassificationApiRow) {
        var icon = getIcon(row);
        var name = getRiskName(row);

        if (!row.submitted) {
            return (
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1 }}>
                    {icon}
                    <Box sx={{ fontWeight: 700 }}>
                        <DotLegalLink
                            linkColor={"primary"}
                            to={getLegalEntityClassificationUrl(row.legalEntity.pageEnum, row.legalEntity.legalEntityId, row.questionnaireResponseId)}
                        >
                            {name}
                        </DotLegalLink>
                    </Box>
                </Box>
            );
        }
        return (
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1 }}>
                {icon}
                <Box>{name}</Box>
            </Box>
        );
    }

    function getIcon(row: LegalEntityClassificationApiRow) {
        if (row.riskLevel || row.riskLevel === 0) {
            return getRiskIcon(row.riskLevel);
        }

        if (!row.scoreEnabled && row.submitted) return getRiskIcon(RiskLevel.None);

        return warning;
    }

    function getRiskName(row: LegalEntityClassificationApiRow) {
        if (row.riskName) {
            return row.riskName;
        }

        if (!row.scoreEnabled && row.submitted) return translateString("none");

        return translateString("answerClassification");
    }

    function getReportButtons(row: LegalEntityClassificationApiRow) {
        if (row.hasRecommendations) {
            return (
                <QuestionnaireReportOverflowButtons
                    reportPdfUrl={getReportPdfUrl(row, false, true)}
                    reportPdfMenuItems={[
                        { menuItem: translateString("downloadWithRecommendations"), onClick: () => onDownloadReportAsPdf(row, true) },
                        {
                            menuItem: translateString("downloadWithoutRecommendations"),
                            onClick: () => onDownloadReportAsPdf(row, false),
                        },
                    ]}
                    showButtons
                    isDownloadingPdf={isDownloadingPdf === row.id}
                />
            );
        }

        return (
            <QuestionnaireReportButtons
                showButtons
                reportPdfUrl={getReportPdfUrl(row, false, false)}
                onDownloadReportPdf={() => onDownloadReportAsPdf(row, false)}
                isDownloadingPdf={isDownloadingPdf === row.id}
            />
        );
    }

    function getHeaders() {
        const headers = Array<ITableHeader<LegalEntityClassificationViewRow>>();
        headers.push({ property: "questionnaireName", text: translateString("template"), align: "left", showOnMobile: true });
        headers.push({ property: "created", text: translateString("created"), align: "left", showOnMobile: false });

        if (props.paqgeType === "legalEntity") {
            headers.push({ property: "systemName", text: translateString("system"), align: "left", showOnMobile: true });
        } else {
            headers.push({ property: "legalEntityName", text: translateString("legalEntity"), align: "left", showOnMobile: true });
        }

        headers.push({ property: "riskLevel", text: translateString("riskLevel"), align: "left", showOnMobile: true });
        headers.push({ property: "score", text: translateString("score"), align: "left", showOnMobile: true });
        headers.push({ property: "submitted", text: translateString("submitted"), align: "left", showOnMobile: true });
        headers.push({ property: "submittedBy", text: translateString("submittedBy"), align: "left", showOnMobile: true });
        headers.push({ property: "evaluation", text: translateString("evaluation"), align: "left", showOnMobile: true });

        headers.push({ property: "evaluationName", text: "", align: "left", showOnMobile: true, hideHeader: true });

        headers.push({ property: "report", text: translateString("classificationReport"), align: "left", showOnMobile: true });
        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });
        return headers;
    }

    return (
        <>
            {hasData ? (
                <DotLegalTableWithControls
                    extraControls={
                        <Grid item xs={2}>
                            {props.paqgeType === "legalEntity" ? (
                                <DotLegalMultiSelect
                                    options={props.data?.selectableSystems}
                                    chosenOptions={selectedSystems}
                                    onChange={setSelectedSystems}
                                    label={translateString("assets")}
                                    noOptionsLabel={translateString("noOptions")}
                                    noMargin
                                ></DotLegalMultiSelect>
                            ) : (
                                <DotLegalMultiSelect
                                    options={props.data?.selectableLegalEntities}
                                    chosenOptions={selectedLegalEntities}
                                    onChange={setSelectedLegalEntities}
                                    label={translateString("legalEntity")}
                                    noOptionsLabel={translateString("noOptions")}
                                    noMargin
                                ></DotLegalMultiSelect>
                            )}
                        </Grid>
                    }
                    headers={getHeaders()}
                    defaultOrderBy={"created"}
                    data={classifications}
                    isLoading={props.isLoading}
                    defaultOrder={"desc"}
                    labelRowsPerPage={""}
                    labelSearch={translateString("search")}
                    noOptionsLabel={""}
                    hideRowsPerPage
                    clickableRows={false}
                    hidePagination
                    emptyText={translateString("noData")}
                    renderRow={(row, i) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                <QuestionnaireTemplateLink type={"classification"} id={row.questionnaireId} name={row.questionnaireName} />
                            </TableCell>
                            <TableCell>{row.created ? translateDate(row.created) : ""}</TableCell>
                            <TableCell>{props.paqgeType === "legalEntity" ? getSystemsUrl(row.system) : getLegalEntityOverviewUrl(row.legalEntity)}</TableCell>
                            <TableCell>{getRisk(row)}</TableCell>
                            <TableCell>{row.scoreName}</TableCell>
                            <TableCell>{row.submitted ? translateDate(row.submitted) : ""}</TableCell>
                            <TableCell>{row.submittedBy}</TableCell>
                            <TableCell>
                                <QuestionnaireEvaluation evaluation={row.evaluation} evaluationStatus={row.evaluationStatus} />
                            </TableCell>
                            <TableCell>{getReportButtons(row)}</TableCell>
                            <TableCell>{formatNote(row)}</TableCell>
                            <TableCell align={"right"}>{row.permissions.edit && <DotLegalOverflowMenu items={getMenuItems(row)} />}</TableCell>
                        </TableRow>
                    )}
                    paginationLabelOf={""}
                    getUserSpecificPageLength={() => 30}
                ></DotLegalTableWithControls>
            ) : (
                <Box
                    sx={{
                        img: {
                            width: "15%",
                        },
                    }}
                >
                    <DotLegalEmptyState icon={<EmptyState />} text={translateString("legalEntityClassificationEmptyState")} />
                </Box>
            )}

            {showDeleteDialog && (
                <DeleteClassificationDialog
                    onDialogClose={() => setShowDeleteDialog(false)}
                    selectedClassification={selectedClassification!}
                    onSuccessDelete={onClassificationDeleted}
                />
            )}

            {showClassificationDialog && (
                <LegalEntityClassificationDialog
                    onCreated={onClassificationCreated}
                    legalEntityId={selectedClassification!.legalEntity.legalEntityId}
                    onDialogClose={() => setShowClassificationDialog(false)}
                />
            )}

            {showNoteDialog && (
                <DotLegalNoteDialog
                    isSaving={isSavingNote}
                    showDialog
                    disabled={!vendorManagementPlan.hasAccess || !selectedClassification?.permissions.edit}
                    noteText={selectedClassification?.note}
                    onDialogClose={() => setShowNoteDialog(false)}
                    onOkClick={(text) => {
                        saveNote(text);
                    }}
                />
            )}

            {showEvaluateDialog && (
                <EvaluateClassificationDialog
                    onEvaluated={onClassificationEvaluated}
                    onDialogClose={() => {
                        setShowEvaluateDialog(false);
                        setSelectedClassification(undefined);
                    }}
                    selectedClassification={selectedClassification!}
                />
            )}

            {showReopenDialog && (
                <ReopenClassificationDialog
                    classificationId={selectedClassification!.id}
                    name={selectedClassification!.questionnaireName}
                    onDialogClose={() => setShowReopenDialog(false)}
                    onInternalAssessmentReopened={() => setShowReopenDialog(false)}
                />
            )}
        </>
    );
}

export default ClassificationTable;
