import React from "react";
import { useRiskWidget } from "./RiskWidget.hooks.ts";
import Widget from "../Widget.tsx";
import { DotLegalHeader, DotLegalSwitch, DotLegalTooltip, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation.ts";
import { Box } from "@mui/material";
import { DashboardRiskOverviewRow } from "./RiskWidget.types.ts";
import { WidgetLock } from "../WidgetLock.tsx";
import RiskWidgetAssessment from "./riskWidgetAssessment/RiskWidgetAssessment.tsx";
import RiskWidgetList from "./riskWidgetList/RiskWidgetList.tsx";

export interface IRiskWidgetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
    isSystemChosen?: boolean;
}

function RiskWidget(props: IRiskWidgetProps) {
    const { translateString } = useTranslation();

    const {
        isLoading,
        onPaOrSystemChange,
        systemsChosen,
        data,
        isEnabled,
        systemsOptionEnabled,
        processingActivityOptionEnabled,
        nis2Enabled,
        hasGdprPlan,
        hasSystemsPlan,
    } = useRiskWidget(props);

    const headers: Array<ITableHeader<DashboardRiskOverviewRow>> = [];
    headers.push({
        property: "name",
        text: systemsChosen ? translateString("asset") : translateString("processingActivity"),
    });
    headers.push({ property: "area", text: translateString("area") });
    headers.push({ property: "riskScore", text: translateString("risk") });

    return (
        <Widget disabled={!isEnabled}>
            <DotLegalHeader marginBottom={0} headerStyle={"medium"}>
                {translateString("risk").toUpperCase()}
            </DotLegalHeader>
            <DotLegalSwitch
                onChange={onPaOrSystemChange}
                checked={systemsChosen}
                disabled={!systemsOptionEnabled || !processingActivityOptionEnabled}
                leftLabel={
                    <Box component={"span"}>
                        {!processingActivityOptionEnabled && (
                            <DotLegalTooltip text={hasGdprPlan ? "" : translateString("upgradePrivacyPlanToday")}>
                                <Box sx={{ display: "inline" }}>
                                    <WidgetLock />
                                </Box>
                            </DotLegalTooltip>
                        )}{" "}
                        {translateString("processingActivities")}
                    </Box>
                }
                rightLabel={
                    <Box component={"span"}>
                        {translateString("assets")}{" "}
                        {!systemsOptionEnabled && (
                            <DotLegalTooltip text={hasSystemsPlan ? "" : translateString("upgradePrivacyPlanToday")}>
                                <Box sx={{ display: "inline" }}>
                                    <WidgetLock />
                                </Box>
                            </DotLegalTooltip>
                        )}
                    </Box>
                }
            />

            <RiskWidgetAssessment marginTop={1} isLoading={isLoading} percentage={data?.gdprPercentage} type="GDPR" />
            {nis2Enabled && <RiskWidgetAssessment marginTop={2} isLoading={isLoading} percentage={data?.nisPercentage} type="NIS2" />}

            <RiskWidgetList data={data} isLoading={isLoading} systemsChosen={systemsChosen} />
        </Widget>
    );
}

export default RiskWidget;
