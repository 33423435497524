import { GdprIncidentLogSaveModel, GdprIncidentType, SeverityLevelEnum } from "../IncidentLogDialog.types";
import { useEffect, useState } from "react";
import { useSelectableDataCategoriesQuery } from "../../processingActivity/hooks/useSelectableDataCategoriesQuery";
import { DataCategoryViewModel } from "../../processingActivity/DataCategoriesStep/DataCategoriesStep.types";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { useMutation, useQuery } from "react-query";
import { get, post, put } from "../../common/api/apiShared";
import { ICreateEditGdprIncidentDialogProps } from "./CreateEditGdprIncidentLogDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { QuestionTypeStandardAnswers } from "../../riskAssessment/RiskAssessment.types";
import { getQuestionCategoryLists } from "../../riskAssessment/riskAssessmentTemplate/RiskAssessmentTemplate.types";
import { SecurityMeasureViewModel } from "../../masterData/securityMeasure/securityMeasures/SecurityMeasures.types";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useSecurityMeasuresQuery } from "../../common/hooks/useSelectableItemQueries.ts";
import { PropertyType } from "../../common/PropertyTypes.ts";

export function useCreateEditGdprIncidentLogDialogDataMapping(props: ICreateEditGdprIncidentDialogProps) {
    let dataCategoriesQuery = useSelectableDataCategoriesQuery();
    const { translateString, translateGdprIncidentType } = useTranslation();
    const snackbar = useDotLegalSnackbar();
    const [saveModel, setSaveModel] = useState<GdprIncidentLogSaveModel>({
        name: "",
        dateOfIncident: null,
        unknownDateOfIncident: false,
        cause: null,
        description: null,
        consequences: null,
        measures: null,
        reportedToAgency: null,
        dateOfReportedToAgency: null,
        reasonForNotReportingToAgency: null,
        affectedPeopleNotified: null,
        reasonForNotNotifyingAffectedPeople: null,
        dateOfAffectedPeopleNotified: null,
        dataCategories: [],
        types: [],
        timeOfIncident: null,
        severity: SeverityLevelEnum.Moderate,
        tags: undefined,
    });
    const [selectedIncidentTypes, setSelectedIncidentTypes] = useState<Array<string>>([]);
    const [errors, setErrors] = useState({ name: "" });
    let dataCategoriesData: Array<DataCategoryViewModel> | undefined;
    const { isLoading, data } = useQuery(`/gdprIncidentLogEntry/${props.id}`, () => get<GdprIncidentLogSaveModel>(`/gdprIncidentLogEntry/${props.id}`), {
        enabled: isEdit(),
    });
    const [timeOfIncident, setTimeOfIncident] = useState<Date | null>(null);

    const incidentTypes: Array<DotLegalSelectOption> = Object.values(GdprIncidentType)
        .filter((value) => typeof value === "number")
        .map((value) => ({ id: value.toString(), name: translateGdprIncidentType(value.toString()) }));

    useEffect(() => {
        if (data) {
            setSaveModel(data);
            if (data.timeOfIncident) {
                setTimeOfIncident(new Date(data.timeOfIncident));
            }

            setSelectedIncidentTypes(data.types.map((value) => value.toString()));
        }
    }, [data]);
    const getIsSelected = (id: string): boolean => {
        if (!saveModel?.dataCategories) return false;
        return !!saveModel.dataCategories.find((x) => x === id);
    };

    if (dataCategoriesQuery.allDataCategories) {
        dataCategoriesData = dataCategoriesQuery.allDataCategories!.map((d) => {
            return {
                name: d.name,
                id: d.id,
                description: d.description,
                classification: d.classification,
                isSelected: getIsSelected(d.id),
            };
        });
    }

    let securityMeasuresQuery = useSecurityMeasuresQuery(true, false);
    let securityMeasures: Array<string> = [];

    if (securityMeasuresQuery.data) {
        securityMeasures = securityMeasuresQuery.data.flatMap((x) => x.name).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
    }
    const saveMutation = useMutation(submitSave);
    function submitSave() {
        let tempModel = { ...saveModel };
        tempModel.types = selectedIncidentTypes.map((value) => Number(value));
        tempModel.timeOfIncident = timeOfIncident;
        setSaveModel(tempModel);

        return post("/gdprIncidentLogEntry/", tempModel);
    }
    const save = () => {
        saveMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackbar.show(translateString("incidentCreatedSnackbar"));
                props.onSave();
            },
        });
    };

    const editMutation = useMutation(submitEdit);

    function submitEdit() {
        let tempModel = { ...saveModel };
        tempModel.types = selectedIncidentTypes.map((value) => Number(value));
        tempModel.timeOfIncident = timeOfIncident;
        setSaveModel(tempModel);

        return put(`/gdprIncidentLogEntry/${props.id}`, tempModel);
    }

    const edit = () => {
        editMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackbar.show(translateString("incidentUpdatedSnackbar"));
                props.onSave();
            },
        });
    };

    let consequenceSuggestions: Array<string> | undefined = getQuestionCategoryLists()
        .flatMap((x) => x.questionTypes)
        .map((x) => new QuestionTypeStandardAnswers(x, translateString).consequenceAnswers)
        .flatMap((x) => x)
        .filter((value, index, array) => array.indexOf(value) === index) //multiple QuestionTypes have some of the same consequences
        .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
    function validateAndSave() {
        let formValid = true;
        let tempErrors = { name: "" };

        if (isNullOrWhitespace(saveModel?.name)) {
            formValid = false;
            tempErrors.name = translateString("fieldMandatory");
        }

        if (formValid) {
            if (isEdit()) edit();
            else save();
        } else {
            setErrors(tempErrors);
        }
    }

    function isEdit() {
        return props.id !== undefined;
    }

    return {
        saveModel,
        setSaveModel,
        selectedIncidentTypes,
        setSelectedIncidentTypes,
        dataCategoriesData,
        validateAndSave,
        errors,
        consequenceSuggestions,
        securityMeasures,
        isLoading,
        isEdit,
        timeOfIncident,
        setTimeOfIncident,
        incidentTypes,
        isSaving: editMutation.isLoading || saveMutation.isLoading,
        severityLevelSelectOptions: getSeverityLeveLSelectOptions(),
    };

    function getSeverityLeveLSelectOptions() {
        return [
            { id: SeverityLevelEnum.VeryLow.toString(), name: translateString("veryLow") },
            { id: SeverityLevelEnum.Low.toString(), name: translateString("low") },
            { id: SeverityLevelEnum.Moderate.toString(), name: translateString("moderate") },
            { id: SeverityLevelEnum.High.toString(), name: translateString("high") },
            { id: SeverityLevelEnum.VeryHigh.toString(), name: translateString("veryHigh") },
        ];
    }
}
