import React, { ReactNode, useEffect } from "react";
import { useAuth } from "oidc-react";
import { Userpilot } from "userpilot";
import { useUserContext } from "./userContextProvider/UserContextProvider.tsx";
import { useLocation } from "react-router-dom";
import { usePlanContext } from "./planProvider/PlanProvider.tsx";
import { PlanType } from "./planProvider/PlanProvider.types.ts";
import { getSettings } from "../common/settingsProvider.ts";

if (!getSettings().isRunningOnLocalHost) {
    Userpilot.initialize(getSettings().userPilotToken);
}

export interface IUserPilotProviderProps {
    children: ReactNode;
}

function UserPilotProvider(props: IUserPilotProviderProps) {
    const auth = useAuth();
    const userContext = useUserContext();
    const location = useLocation();
    const plan = usePlanContext();

    useEffect(() => {
        Userpilot.identify(userContext.userProfileId, {
            company: {
                id: userContext.customerId,
                name: userContext.customerName,
            },
            plan: PlanType[plan.planType],
            language: userContext.language,
            created_at: new Date(userContext.created).toISOString().split("T")[0], // yyyy-mm-dd format,
        });
    }, [
        auth.userData?.session_state,
        plan.planType,
        userContext.created,
        userContext.customerId,
        userContext.customerName,
        userContext.language,
        userContext.userProfileId,
    ]);

    React.useEffect(() => {
        // Userpilot must be reloaded on every route change through Userpilot's reload function
        Userpilot.reload();
    }, [location]);

    return <>{props.children}</>;
}

export default UserPilotProvider;
