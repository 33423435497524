import { Box, Typography } from "@mui/material";
import React from "react";
import { DotLegalButton, DotLegalCheckbox } from "@dotlegal/dotlegal-ui-components";
import DotLegalCollapse from "../../common/components/dotLegalCollapse/DotLegalCollapse";
import { useTranslation } from "../../localization/useTranslation";
import StepSegment from "../stepSegment/StepSegment";
import SegmentStep from "../stepSegment/StepSegment";
import SystemSelector from "./systemSelector/SystemSelector";
import { useSystemStepStyles } from "./SystemStep.styles";
import { ProcessingActivityModel, ProcessingActivitySystemSubDataProcessor, StepTypeEnum } from "../ProcessingActivity.types";
import { ValidationError } from "../../common/validationError";
import { dataProcessorIcon } from "../../common/icons";
import { useUrlProvider } from "../../useUrlProvider";
import { useSystemStepDataMapping } from "./SystemStep.hooks";
import DotLegalPersonalDataSelector from "../../common/components/dotLegalPersonalDataSelector/DotLegalPersonalDataSelector";
import DotLegalStepHeader from "../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import LegalEntityTable from "../../legalEntity/legalEntityTable/LegalEntityTable";
import SubProcessorComponent from "../sharingOfDataStep/dataProcessorsStep/subProcessorComponent/SubProcessorComponent";
import { DataProcessor, SubProcessor } from "../sharingOfDataStep/dataProcessorsStep/subProcessorComponent/SubProcessorComponent.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export interface ISystemStep {
    onDisableSystemsChange: (checked: boolean) => void;
    disableSystems: boolean;
    validationErrors: Array<ValidationError>;
    processingActivityModel: ProcessingActivityModel;
    isReadOnly: boolean;
}

function SystemStep(props: ISystemStep) {
    const {
        isAddingNewSystem,
        handleSystemChange,
        handleAddSystem,
        handleRemoveSystem,
        handleCollapseClick,
        getExpandedId,
        setIsAddingNewSystem,
        dataCategoriesData,
        isDataCategoriesLoading,
        chosenDataCategories,
        handleChosenDataCategoriesChanged,
        chosenLegalEntities,
        handleLegalEntitiesChange,
        isGroupEntitiesLoading,
        groupEntities,
        systemsLoading,
        systemsData,
        handeSubProcessorDataCategoryChange,
        legalEntitiesData,
        isLegalEntitiesLoading,
        transferBasisData,
        isTransferBasisLoading,
        documentsLoading,
        transferImpactAssessmentDocs,
        onSystemCreated,
    } = useSystemStepDataMapping(props.processingActivityModel);

    const { translateString } = useTranslation();
    const { getEditProcessingActivityUrl } = useUrlProvider();
    const styles = useSystemStepStyles();
    const { permissions } = useUserContext();

    const processingActivitySystems = props.processingActivityModel.systems.map((s) => s.systemId);
    const getSystems = (currentSystem: string) => {
        if (systemsData && processingActivitySystems) {
            return systemsData.filter((sys) => processingActivitySystems.indexOf(sys.id) < 0 || currentSystem === sys.id);
        }
        return undefined;
    };

    const getCenterContext = (systemName?: string) => {
        return <Typography sx={styles.collapseHeaderText}>{systemName}</Typography>;
    };

    const getSubProcessors = (processingActivitySystemSubProcessor: ProcessingActivitySystemSubDataProcessor, index: number) => {
        const dataProcessor: DataProcessor = {
            id: "",
            createdAsGroupEntityIsDataProcessor: false,
            dataCategories: chosenDataCategories ?? [],
            isSystemGenerated: true,
            readOnly: true,
        };

        let subDataProcessor = systemsData
            .find((s) => s.id === getExpandedId())
            ?.subProcessors.find((s) => s.id === processingActivitySystemSubProcessor.systemSubDataProcessorId)!;

        const subProcessorViewModel: SubProcessor = {
            dataCategories: processingActivitySystemSubProcessor?.dataCategoryIds,
            processorId: subDataProcessor?.legalEntityId,
            subProcessHasAgreement: subDataProcessor?.hasDataProcessorAgreement,
            transferBasisId: subDataProcessor?.transferBasisId,
            hasTransferImpactAssessment: subDataProcessor?.hasTransferImpactAssessment,
            transferImpactAssessmentId: subDataProcessor?.transferImpactAssessmentId,
            dataStorageLocations: [],
            supportLocations: [],
            containsNotAccessibleTia: false,
            isLink: subDataProcessor?.isLink,
            link: subDataProcessor?.link,
            id: subDataProcessor?.id,
        };

        let isLoading = documentsLoading || systemsLoading || isLegalEntitiesLoading || isTransferBasisLoading || isDataCategoriesLoading;
        return (
            <SubProcessorComponent
                key={processingActivitySystemSubProcessor.systemSubDataProcessorId}
                documentsData={transferImpactAssessmentDocs}
                documentsLoading={isLoading}
                dataProcessor={dataProcessor}
                subProcessor={subProcessorViewModel}
                dataCategoriesData={dataCategoriesData}
                groupEntityIsDataProcessor={false}
                subProcessorIndex={index}
                legalEntityData={legalEntitiesData}
                isDataCategoryLoading={isLoading}
                isLegalEntityLoading={isLoading}
                validationErrors={[]}
                isReadOnly={props.isReadOnly}
                isTransferBasisLoading={isLoading}
                transferBasisData={transferBasisData}
                onSubProcessorDataCategoryChange={(chosenCategories) =>
                    handeSubProcessorDataCategoryChange(processingActivitySystemSubProcessor.systemSubDataProcessorId, chosenCategories)
                }
                thirdCountries={[]}
                onLinkChange={() => {}}
                shouldAlwaysDisableLink
            />
        );
    };

    const getCollapseIcon = (systemId: string) => {
        if (permissions.processingActivityDataProcessorsPermissions) {
            let sharings = props.processingActivityModel.dataProcessors.filter((x) => x.isSystemGenerated && systemId === x.systemId);

            const sharingId = sharings.length > 0 ? sharings[0].id : undefined;
            return sharingId === undefined || !sharings[0].hasDataProcessorAccess
                ? undefined
                : {
                      icon: dataProcessorIcon,
                      iconHoverText: translateString("systemGeneratesDataprocessorSharings"),
                      href: getEditProcessingActivityUrl(props.processingActivityModel.id, StepTypeEnum.sharings, StepTypeEnum.dataprocessor, sharingId),
                  };
        }

        return undefined;
    };

    return (
        <StepSegment size={"large"}>
            <Typography sx={styles.systemHeader}>{translateString("systemsOnProcessActivity")}</Typography>

            {props.processingActivityModel.systems.map((system, index) => {
                const systemId = system.systemId;
                return (
                    <DotLegalCollapse
                        key={system.systemId}
                        id={systemId}
                        isLoading={systemsLoading}
                        sx={styles.collapse}
                        isExpanded={getExpandedId() === systemId}
                        centerContent={getCenterContext(systemsData?.find((x) => x.id === systemId)?.name)}
                        removeItemText={translateString("removeSystem")}
                        onExpandClick={() => handleCollapseClick(systemId)}
                        onRemoveClick={() => handleRemoveSystem(index, systemId)}
                        disabled={props.isReadOnly}
                        collapseIcon={getCollapseIcon(systemId)}
                    >
                        {systemId === getExpandedId() && (
                            <Box sx={styles.container}>
                                <Box sx={styles.systemBox}>
                                    <SystemSelector
                                        isLoading={systemsLoading}
                                        onSystemChange={(id) => handleSystemChange(id!)}
                                        systems={getSystems(systemId)}
                                        chosenSystemId={systemId}
                                        readOnly={props.isReadOnly}
                                        onSystemCreated={async (id) => {
                                            await handleSystemChange(id);
                                            onSystemCreated();
                                        }}
                                    />
                                </Box>

                                <DotLegalStepHeader record={false} topMargin bottomMargin>
                                    {translateString("whichDataCategoriesInSystem")}
                                </DotLegalStepHeader>

                                {dataCategoriesData && (
                                    <SegmentStep size="large">
                                        <DotLegalPersonalDataSelector
                                            disabled={props.isReadOnly}
                                            activityDataCategories={dataCategoriesData!.map((dataCategory) => {
                                                return {
                                                    name: dataCategory.name,
                                                    id: dataCategory.id,
                                                    description: dataCategory.description,
                                                    classification: dataCategory.classification,
                                                    isSelected: false,
                                                };
                                            })}
                                            chosenCategories={chosenDataCategories}
                                            onChosenActivityDataCategories={handleChosenDataCategoriesChanged}
                                        />
                                    </SegmentStep>
                                )}

                                <SegmentStep size="large">
                                    <DotLegalStepHeader record={false} subHeader={translateString("entityChosenOnAssociation")} topMargin bottomMargin>
                                        {translateString("companiesUsingSystem")}
                                    </DotLegalStepHeader>
                                    <LegalEntityTable
                                        disabled={props.isReadOnly}
                                        isLoading={isGroupEntitiesLoading}
                                        legalEntities={groupEntities}
                                        selectedEntities={chosenLegalEntities}
                                        showAddNewEntityButton={false}
                                        showAddAllEntityButton
                                        onLegalEntitiesChange={handleLegalEntitiesChange}
                                    />
                                    {system.subDataProcessors.length > 0 && (
                                        <>
                                            <DotLegalStepHeader topMargin bottomMargin>
                                                {translateString("subprocessors")}
                                            </DotLegalStepHeader>
                                            <Box marginBottom={3}>{system.subDataProcessors.map((sub, index) => getSubProcessors(sub, index))}</Box>
                                        </>
                                    )}
                                </SegmentStep>
                            </Box>
                        )}
                    </DotLegalCollapse>
                );
            })}

            {isAddingNewSystem && (
                <DotLegalCollapse
                    sx={styles.collapse}
                    isExpanded
                    centerContent={"------------"}
                    removeItemText={translateString("removeSystem")}
                    onExpandClick={() => {}}
                    onRemoveClick={() => setIsAddingNewSystem(false)}
                    disabled={props.isReadOnly}
                >
                    <Box sx={styles.systemBox}>
                        <SystemSelector
                            isLoading={systemsLoading}
                            onSystemChange={(id) => handleSystemChange(id!)}
                            systems={getSystems("")}
                            chosenSystemId={undefined}
                            readOnly={props.isReadOnly}
                            onSystemCreated={async (id) => {
                                await handleSystemChange(id);
                                onSystemCreated();
                            }}
                        />
                    </Box>
                </DotLegalCollapse>
            )}
            <Box sx={styles.addSystem}>
                <DotLegalButton
                    buttonType="secondary"
                    disabled={isAddingNewSystem || props.disableSystems || props.isReadOnly}
                    onClick={() => handleAddSystem()}
                >
                    {processingActivitySystems.length > 0
                        ? translateString("addAnotherSystemToProcessingActivity")
                        : translateString("addSystemToProcessingActivity")}
                </DotLegalButton>
            </Box>

            {processingActivitySystems.length === 0 && !isAddingNewSystem && (
                <Box sx={styles.checkboxContainer}>
                    <DotLegalCheckbox
                        checked={props.disableSystems}
                        onChange={props.onDisableSystemsChange}
                        label={translateString("disableSystems")}
                        disabled={props.isReadOnly}
                    />
                </Box>
            )}
            {props.validationErrors.length > 0 && <Box sx={styles.error}>{props.validationErrors[0].error}</Box>}
        </StepSegment>
    );
}

export default SystemStep;
