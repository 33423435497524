import React from "react";
import { DotLegalButton, DotLegalPaper, DotLegalTableWithControls, ITableHeader, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useLegalEntityAuditTabDataMapping } from "./LegalEntityAuditTab.hooks";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import { LegalEntityAuditApiModel, LegalEntityAuditRowModel, LegalEntityAuditStatus } from "./LegalEntityAudit.types";
import { useUrlProvider } from "../../useUrlProvider";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import { NoteSVG } from "../../common/icons";
import NoSharings from "../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import SendAuditDialog from "./sendAuditDialog/SendAuditDialog";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import QuestionnaireReportButtons from "../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportButtons";
import QuestionnaireStatus from "../../common/components/questionnaireComponents/questionnaireStatus/QuestionnaireStatus";
import QuestionnaireRisk from "../../common/components/questionnaireComponents/questionnaireRisk/QuestionnaireRisk";
import QuestionnaireReportOverflowButtons from "../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportOverflowButtons";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";
import AddToAuditGroup from "./addToAuditGroup/AddToAuditGroup";
import { isNullOrWhitespace } from "../../common/stringOperations";
import LegalEntityAuditTableOverflowMenu from "./legalEntityAuditTableOverflowMenu/LegalEntityAuditTableOverflowMenu";
import QuestionnaireTemplateLink from "./QuestionnaireTemplateLink.tsx";
import SystemLinks from "../../system/SystemLinks.tsx";
import QuestionnaireEvaluation from "../../common/components/questionnaireComponents/questionnaireEvaluation/QuestionnaireEvaluation.tsx";
import { IDownloadAuditReportModel } from "../../legalEntityAuditGroup/DowloadLegalEntityAuditReport.hooks.ts";

export interface ILegalEntityAuditProps {
    legalEntityId: string | undefined;
    legalEntityName: string | undefined;
}

function LegalEntityAudit(props: ILegalEntityAuditProps) {
    const { translateString, translateDate } = useTranslation();
    const { vendorManagementPlan } = usePlanContext();
    const { permissions } = useUserContext();

    const {
        onSendAuditClick,
        showNote,
        setShowNote,
        isLoading,
        audits,
        saveNote,
        hasAudits,
        showDialog,
        setShowDialog,
        onAuditSent,
        onAuditClosed,
        onAuditReopened,
        onAuditDeleted,
        onDownloadReportAsPdf,
        isDownloadingPdf,
        getReportPdfUrl,
        onResponsibleChanged,
        onContactChanged,
        onReminderSent,
        showAddToAuditGroup,
        setShowAddToAuditGroup,
        onAuditAddedToAuditGroup,
        onAuditEvaluated,
        onRemindersToggled,
        onAuditCopied,
        setShowCopyDialog,
        showCopyDialog,
        selectedAudit,
        setSelectedAudit,
    } = useLegalEntityAuditTabDataMapping(props.legalEntityId, props.legalEntityName!);
    const urlProvider = useUrlProvider();

    const legalEntityPermissions = permissions.companyPermissions;

    function getHeaders() {
        const headers = Array<ITableHeader<LegalEntityAuditRowModel>>();
        headers.push({ property: "questionnaireName", text: translateString("template"), align: "left", showOnMobile: true });
        headers.push({ property: "questionnaireVersion", text: translateString("version"), align: "left", showOnMobile: true });
        headers.push({ property: "uniqueIdentifier", text: "Id", align: "left", showOnMobile: true });
        headers.push({ property: "sent", text: translateString("legalEntityAuditSent"), align: "left", showOnMobile: true });
        headers.push({ property: "deadline", text: translateString("deadline"), align: "left", showOnMobile: true });
        headers.push({ property: "systemsString", text: translateString("assets"), align: "left", showOnMobile: true });
        headers.push({ property: "contactName", text: translateString("contact"), align: "left", showOnMobile: true });
        headers.push({ property: "responsibleName", text: translateString("responsible"), align: "left", showOnMobile: true });
        headers.push({ property: "sender", text: translateString("sentFrom"), align: "left", showOnMobile: true });
        headers.push({ property: "auditGroupName", text: translateString("auditGroup"), align: "left", showOnMobile: true });
        headers.push({ property: "statusName", text: translateString("status"), align: "left", showOnMobile: true });
        headers.push({ property: "riskLevelName", text: translateString("riskLevel"), align: "left", showOnMobile: true });
        headers.push({ property: "scoreName", text: translateString("score"), align: "left", showOnMobile: true });
        headers.push({ property: "evaluation", text: translateString("evaluation"), align: "left", showOnMobile: true });

        headers.push({ property: "evaluationName", text: "", align: "left", showOnMobile: true, hideHeader: true });

        headers.push({ property: "report", text: translateString("legalEntityAuditReport"), align: "left", showOnMobile: true });
        headers.push({ property: "reminderSent", text: translateString("headerReminderSent"), align: "left", showOnMobile: true });
        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });
        return headers;
    }

    function getMenuItems(row: LegalEntityAuditApiModel) {
        let menuItems = [];

        if (vendorManagementPlan.hasAccess && !row.auditGroup && legalEntityPermissions.edit) {
            menuItems.push({
                menuItem: translateString("addToAuditGroup"),
                onClick: () => {
                    setShowAddToAuditGroup(row.id);
                },
            });
        }

        if (vendorManagementPlan.hasAccess && legalEntityPermissions.create) {
            menuItems.push({
                menuItem: translateString("sendCopyOfAudit"),
                onClick: () => {
                    setSelectedAudit(row);
                    setShowCopyDialog(true);
                },
            });
        }

        return menuItems;
    }

    function getAuditGroupUrl(auditGroup?: BaseViewModel) {
        if (!auditGroup) {
            return undefined;
        }

        return (
            <Box key={auditGroup.id} component={"span"} sx={{ fontWeight: "bold" }}>
                <DotLegalLink linkColor={"primary"} to={urlProvider.getAuditGroupUrl(auditGroup.id)}>
                    {auditGroup.name}
                </DotLegalLink>
            </Box>
        );
    }

    function formatNote(row: LegalEntityAuditApiModel) {
        return (
            row.note && (
                <IconButton
                    onClick={() => {
                        setShowNote(row.id);
                    }}
                >
                    {NoteSVG}
                </IconButton>
            )
        );
    }

    function getStatus(row: LegalEntityAuditApiModel) {
        return (
            <QuestionnaireStatus
                showQuestionsStatistics={row.showQuestionsStatistics}
                numberOfQuestionsAnswered={row.numberOfQuestionsAnswered}
                totalNumberOfQuestions={row.totalNumberOfQuestions}
                status={row.status}
                lastestRecipientUpdate={row.lastestRecipientUpdate}
            />
        );
    }

    function getRisk(row: LegalEntityAuditApiModel) {
        return <QuestionnaireRisk riskLevel={row.riskLevel} riskName={row.riskName} scoreEnabled={row.scoreEnabled} status={row.status} />;
    }

    function getDownloadAuditReportModel(row: LegalEntityAuditApiModel) {
        return { legalEntityName: props.legalEntityName ?? "", legalEntityAuditId: row.id, ...row };
    }

    function getReportButtons(row: LegalEntityAuditApiModel) {
        let showButtons = row.status !== LegalEntityAuditStatus.Opened && row.status !== LegalEntityAuditStatus.Sent && !row.isClosed;
        let isDownloading = isDownloadingPdf === row.id;
        let reportUrl = getReportPdfUrl(row.questionnaireResponseId, row.deadline, row.sender, props.legalEntityName ?? "", false, row.hasRecommendations);

        if (row.scoreEnabled || row.hasRecommendations) {
            return (
                <QuestionnaireReportOverflowButtons
                    reportPdfUrl={reportUrl}
                    reportPdfMenuItems={getReportButtonMenuItems(row)}
                    showButtons={showButtons}
                    isDownloadingPdf={isDownloading}
                />
            );
        }

        return (
            <QuestionnaireReportButtons
                isDownloadingPdf={isDownloading}
                onDownloadReportPdf={() => onDownloadReportAsPdf(getDownloadAuditReportModel(row), true, false)}
                reportPdfUrl={reportUrl}
                showButtons={showButtons}
            />
        );
    }

    function getReportButtonMenuItems(row: LegalEntityAuditApiModel) {
        if (row.scoreEnabled) {
            return [
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithScoreAndRecommendations") : translateString("downloadWithScore"),
                    onClick: () => onDownloadReportAsPdf(getDownloadAuditReportModel(row), false, row.hasRecommendations),
                },
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithoutScoreAndRecommendations") : translateString("downloadWithoutScore"),
                    onClick: () => onDownloadReportAsPdf(getDownloadAuditReportModel(row), true, false),
                },
            ];
        } else {
            return [
                {
                    menuItem: translateString("downloadWithRecommendations"),
                    onClick: () => onDownloadReportAsPdf(getDownloadAuditReportModel(row), false, true),
                },
                {
                    menuItem: translateString("downloadWithoutRecommendations"),
                    onClick: () => onDownloadReportAsPdf(getDownloadAuditReportModel(row), false, false),
                },
            ];
        }
    }

    return (
        <DotLegalPaper>
            {hasAudits ? (
                <DotLegalTableWithControls
                    headers={getHeaders()}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    defaultOrderBy={"sent"}
                    defaultOrder={"desc"}
                    isLoading={isLoading}
                    clickableRows={false}
                    data={audits}
                    emptyText={translateString("noData")}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    renderRow={(row, i) => (
                        <TableRow
                            key={i}
                            sx={{
                                "& svg": {
                                    height: "100%",
                                },
                            }}
                        >
                            <TableCell>
                                <QuestionnaireTemplateLink type={"audit"} id={row.questionnaireId} name={row.questionnaireName} />
                            </TableCell>
                            <TableCell>{row.questionnaireVersion}</TableCell>
                            <TableCell>{row.uniqueIdentifier}</TableCell>
                            <TableCell>{translateDate(row.sent)}</TableCell>
                            <TableCell>{translateDate(row.deadline)}</TableCell>
                            <TableCell>
                                <SystemLinks systems={row.assets} />
                            </TableCell>
                            <TableCell>{row.contactName}</TableCell>
                            <TableCell>{row.responsibleName}</TableCell>
                            <TableCell>{row.sender}</TableCell>
                            <TableCell>{getAuditGroupUrl(row.auditGroup)}</TableCell>
                            <TableCell>{getStatus(row)}</TableCell>
                            <TableCell>{getRisk(row)}</TableCell>
                            <TableCell>{row.scoreName}</TableCell>
                            <TableCell>
                                <QuestionnaireEvaluation evaluation={row.evaluation} evaluationStatus={row.evaluationStatus} />
                            </TableCell>
                            <TableCell>{getReportButtons(row)}</TableCell>
                            <TableCell>
                                {row.remindersPaused ? translateString("remindersPaused") : row.reminderSent ? translateDate(row.reminderSent) : ""}
                            </TableCell>
                            <TableCell>{formatNote(row)}</TableCell>
                            <TableCell align={"right"}>
                                {!isLoading && (
                                    <LegalEntityAuditTableOverflowMenu
                                        key={row.id}
                                        auditId={row.id}
                                        isClosed={row.isClosed}
                                        status={row.status}
                                        canBeDeleted={row.canBeDeleted}
                                        questionnaireName={row.questionnaireName}
                                        deadline={row.deadline}
                                        auditNote={row.note}
                                        responsibleId={row.responsible.id}
                                        contactId={row.contact.id}
                                        contactName={row.contact.name}
                                        legalEntityId={props.legalEntityId!}
                                        responseExternalId={row.responseExternalId}
                                        evaluation={row.evaluation}
                                        evaluationStatus={row.evaluationStatus}
                                        onAuditClosed={onAuditClosed}
                                        onAuditReopened={onAuditReopened}
                                        onAuditDeleted={onAuditDeleted}
                                        onResponsibleChanged={onResponsibleChanged}
                                        onContactChanged={onContactChanged}
                                        onReminderSent={onReminderSent}
                                        additionalMenuItems={getMenuItems(row)}
                                        onCloseNoteDialog={() => setShowNote(undefined)}
                                        saveNote={(note) => saveNote(note, row.id)}
                                        showNote={showNote === row.id}
                                        disableChangeResponsible={row.auditGroup !== null && row.auditGroup !== undefined}
                                        onAuditEvaluated={onAuditEvaluated}
                                        onRemindersToggled={onRemindersToggled}
                                        remindersPaused={row.remindersPaused}
                                        questionnaireResponseId={row.questionnaireResponseId}
                                        questionnaireId={row.questionnaireId}
                                        legalEntityName={props.legalEntityName!}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                />
            ) : (
                <DotLegalEmptyState icon={<NoSharings />} text={translateString("noLegalEntityAudits")} />
            )}
            {vendorManagementPlan.hasAccess && legalEntityPermissions.create && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <DotLegalButton buttonType={"primary"} onClick={onSendAuditClick}>
                        {translateString("sendAudit")}
                    </DotLegalButton>
                </Box>
            )}

            {showDialog && (
                <SendAuditDialog
                    legalEntityId={props.legalEntityId}
                    legalEntityName={props.legalEntityName!}
                    onAuditSent={onAuditSent}
                    onDialogClose={() => setShowDialog(false)}
                />
            )}

            {audits && !isNullOrWhitespace(showAddToAuditGroup) && (
                <AddToAuditGroup
                    auditId={showAddToAuditGroup!}
                    onCloseDialog={() => setShowAddToAuditGroup(undefined)}
                    selectableAuditGroups={audits.find((x) => x.id === showAddToAuditGroup)!.selectableAuditGroups}
                    onAddedToAuditGroup={onAuditAddedToAuditGroup}
                />
            )}

            {showCopyDialog && (
                <SendAuditDialog
                    onDialogClose={() => setShowCopyDialog(false)}
                    onAuditSent={() => {
                        onAuditCopied();
                        setShowCopyDialog(false);
                    }}
                    legalEntityId={props.legalEntityId}
                    legalEntityName={props.legalEntityName!}
                    questionnaire={{
                        id: selectedAudit!.questionnaireId,
                        name: selectedAudit!.questionnaireName,
                    }}
                    responseId={selectedAudit!.questionnaireResponseId}
                />
            )}
        </DotLegalPaper>
    );
}

export default LegalEntityAudit;
