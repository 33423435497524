import { Box, Grid } from "@mui/material";
import React, { createElement, createRef, useCallback, useMemo, useState } from "react";
import DotLegalInformationBox from "../../common/components/dotLegalInformationBox/DotLegalInformationBox";
import {
    DotLegalButton,
    DotLegalDialog,
    DotLegalHeader,
    DotLegalInfoDialog,
    DotLegalInformationTooltip,
    DotLegalPageHeader,
    DotLegalTooltip,
    useStateLocalStorage,
} from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import DotLegalTabs from "../../common/components/dotLegalTabs/DotLegalTabs";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types";
import { useTranslation } from "../../localization/useTranslation";
import { useRiskAssessmentTemplateDataMapping } from "./RiskAssessmentTemplate.hooks";
import { useRiskAssessmentTemplateStyles } from "./RiskAssessmentTemplate.styles";
import { RiskAssessmentTemplateItemType, RiskAssessmentTemplateItemViewModel } from "./RiskAssessmentTemplate.types";
import RiskAssessmentTemplateCollapse from "./riskAssessmentTemplateCollapse/RiskAssessmentTemplateCollapse";
import DotLegalFullScreenSpinner from "../../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";
import { QuestionCategory, QuestionCategoryQuestions, QuestionType } from "../RiskAssessment.types";
import { useQuestionToolTip } from "../useQuestionToolTip";
import EmptyTemplateState from "./EmptyTemplateState.svg?react";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Trans } from "react-i18next";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useRiskAssessmentCustomQuestions } from "../riskAssessmentTemplateCustomQuestions/RiskAssessmentCustomQuestions.hooks";
import CreateEditCustomQuestion from "../riskAssessmentTemplateCustomQuestions/createEdit/CreateEditCustomQuestion";
import { riskAreaAndComplianceAreaLocalStorageModel, RiskAssessmentArea, RiskComplianceArea } from "../riskAssessments/RiskAssessments.types";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import "./RiskAssessmentTemplate.css";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import { CustomQuestionType } from "../riskAssessmentTemplateCustomQuestions/RiskAssessmentCustomQuestions.types";
import { riskAreaAndComplianceAreaKeyStorageKey } from "../riskAssessments/RIskAssessments.hooks";

enum SelectedTabEnum {
    "Questions",
    "CustomQuestions",
    "Classifications",
}

export interface IRiskAssessmentTemplateProps {}

function RiskAssessmentTemplate(props: IRiskAssessmentTemplateProps) {
    const [riskAreaAndComplianceArea] = useStateLocalStorage<riskAreaAndComplianceAreaLocalStorageModel>(riskAreaAndComplianceAreaKeyStorageKey, {
        riskArea: RiskAssessmentArea.ProcessingActivities,
        complianceArea: RiskComplianceArea.GDPR,
        versionId: null,
    });

    const riskAssessmentArea: RiskAssessmentArea = Number(riskAreaAndComplianceArea.riskArea);
    const riskComplianceArea: RiskComplianceArea = Number(riskAreaAndComplianceArea.complianceArea);

    const { getQuestionToolTip } = useQuestionToolTip();
    const { translateString } = useTranslation();
    const styles = useRiskAssessmentTemplateStyles();
    const { permissions, customerName } = useUserContext();
    const { riskAndClassificationPlan } = usePlanContext();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [editQuestionOpen, setEditQuestionOpen] = useState<boolean>(false);
    const [createQuestionOpen, setCreateQuestionOpen] = useState<boolean>(false);
    const [customQuestionToDelete, setCustomQuestionToDelete] = useState<RiskAssessmentTemplateItemViewModel>();
    const [customQuestionToEdit, setCustomQuestionToEdit] = useState<RiskAssessmentTemplateItemViewModel>();

    const {
        customQuestions,
        customQuestion,
        setCustomQuestion,
        onSaveCustomQuestion,
        newCustomQuestion,
        setNewCustomQuestion,
        onDeleteCustomQuestion,
        getCustomQuestionDisplayText,
        customQuestionValidator,
    } = useRiskAssessmentCustomQuestions(riskAssessmentArea, riskComplianceArea, riskAreaAndComplianceArea.versionId!, {
        editCustomQuestionId: customQuestionToEdit?.customQuestionId,
        fetchCustomQuestions: true,
    });
    const {
        getBreadCrumbs,
        selectedTab,
        setSelectedTab,
        data,
        onTemplateItemToggle,
        onBusinessAreaToggle,
        expandedItem,
        onExpandToggle,
        isLoading,
        businessAreas,
        onScoreChange,
        categories,
        itemForRemoval,
        onWarningDialogClose,
        isRiskAssessmentTemplateItemLimitReached,
        isLocked,
    } = useRiskAssessmentTemplateDataMapping(riskAssessmentArea, riskComplianceArea, riskAreaAndComplianceArea.versionId!, {
        editCustomQuestionId: customQuestionToEdit?.customQuestionId,
        customerCustomQuestions: customQuestions,
        getCustomQuestionDisplayText,
    });

    const classificationTab = 2;
    const riskType = useMemo(() => {
        switch (selectedTab) {
            case SelectedTabEnum.Questions:
                return translateString("questions");
            case SelectedTabEnum.CustomQuestions:
                return translateString("customQuestions");
            case SelectedTabEnum.Classifications:
                return translateString("riskClassification");
            default:
                return translateString("questions");
        }
    }, [selectedTab, translateString]);

    const items = useMemo(() => {
        switch (selectedTab) {
            case SelectedTabEnum.Questions:
                return data.questions;
            case SelectedTabEnum.CustomQuestions:
                return data.customQuestions;
            case SelectedTabEnum.Classifications:
                return data.scenarios;
            default:
                return data.questions;
        }
    }, [data, selectedTab]);

    const showDeleteButton = useMemo(() => {
        return customQuestionToEdit?.questionType === QuestionType.CustomQuestion && permissions.riskTemplatePermissions.delete;
    }, [customQuestionToEdit?.questionType, permissions.riskTemplatePermissions.delete]);

    const GetQuestionBox = useCallback(
        (itemViewModel: RiskAssessmentTemplateItemViewModel) => (
            <Box key={itemViewModel.key()} sx={styles.templateContainer} display={"flex"} alignItems={"center"} gap={1}>
                <DotLegalInformationBox
                    isSelected={itemViewModel.selected ? "selected" : "unselected"}
                    size="small"
                    fullWidth
                    header={itemViewModel.name}
                    description={
                        itemViewModel.itemType === RiskAssessmentTemplateItemType.Probability ? getQuestionToolTip(itemViewModel.questionType!) : undefined
                    }
                    onChange={(_) => onTemplateItemToggle(itemViewModel)}
                    isDisabled={
                        isLocked ||
                        !permissions.riskTemplatePermissions.edit ||
                        (itemViewModel.itemType === RiskAssessmentTemplateItemType.Probability &&
                            isRiskAssessmentTemplateItemLimitReached &&
                            !itemViewModel.selected)
                    }
                />
                {itemViewModel.itemType === RiskAssessmentTemplateItemType.Probability &&
                    isRiskAssessmentTemplateItemLimitReached &&
                    !itemViewModel.selected && (
                        <DotLegalTooltip text={translateString("templateLimitReached", { count: riskAndClassificationPlan.maxNumberOfTemplates })}>
                            <span>
                                <PurpleLockSvg />
                            </span>
                        </DotLegalTooltip>
                    )}
                {itemViewModel.questionType === QuestionType.CustomQuestion && permissions.riskTemplatePermissions.edit ? (
                    <>
                        <Box>
                            <DotLegalButton
                                buttonType={"secondary"}
                                onClick={() => {
                                    setCustomQuestionToEdit(itemViewModel);
                                    setEditQuestionOpen(true);
                                }}
                                size={"large"}
                                disabled={isLocked}
                            >
                                {translateString("edit")}
                            </DotLegalButton>
                        </Box>
                    </>
                ) : (
                    <></>
                )}
            </Box>
        ),
        [
            styles.templateContainer,
            getQuestionToolTip,
            onTemplateItemToggle,
            permissions.riskTemplatePermissions.edit,
            translateString,
            isRiskAssessmentTemplateItemLimitReached,
            riskAndClassificationPlan,
            isLocked,
        ]
    );

    const getContent = useCallback(
        (templates: Array<RiskAssessmentTemplateItemViewModel>, header: string, toolTip: string) => {
            return (
                <React.Fragment>
                    <DotLegalHeader headerStyle={"medium"} toolTip={toolTip}>
                        {header}
                    </DotLegalHeader>

                    {templates.map((x) => {
                        return GetQuestionBox(x);
                    })}
                </React.Fragment>
            );
        },
        [GetQuestionBox]
    );

    const getProbabilityContent = useCallback(
        (
            questions: Array<RiskAssessmentTemplateItemViewModel>,
            categories: Array<QuestionCategoryQuestions>,
            header: string,
            isCustomQuestionsTab?: boolean
        ) => {
            return (
                <React.Fragment>
                    <DotLegalHeader
                        headerStyle={"medium"}
                        toolTip={translateString("templateQuestionTooltip", {
                            riskAreaPlural: setFirstLetterToLowerCase(
                                riskAssessmentArea === RiskAssessmentArea.ProcessingActivities
                                    ? translateString("processingActivities")
                                    : translateString("systems")
                            ),
                        })}
                    >
                        {header}
                    </DotLegalHeader>
                    {isCustomQuestionsTab ? (
                        <>
                            {permissions.riskTemplatePermissions.create && (
                                <>
                                    <Box display={"flex"} justifyContent={"center"} marginY={2}>
                                        <DotLegalButton disabled={isLocked} onClick={() => setCreateQuestionOpen(true)} buttonType={"primary"}>
                                            {translateString("createCustomQuestion")}
                                        </DotLegalButton>
                                    </Box>
                                    <DotLegalDialog
                                        header={translateString("createEditCustomQuestionDialogHeader")}
                                        buttonOkText={translateString("create")}
                                        size={"md"}
                                        open={createQuestionOpen}
                                        onDialogClose={() => {
                                            setCreateQuestionOpen(false);
                                            setNewCustomQuestion({
                                                isNew: true,
                                                riskAssessmentArea: riskAssessmentArea,
                                                riskComplianceArea: riskComplianceArea,
                                            } as CustomQuestionType);
                                        }}
                                        onOkClick={async () => {
                                            const saveResult = await onSaveCustomQuestion();
                                            if (saveResult) {
                                                setCreateQuestionOpen(false);
                                                setNewCustomQuestion({
                                                    isNew: true,
                                                    riskAssessmentArea: riskAssessmentArea,
                                                    riskComplianceArea: riskComplianceArea,
                                                } as CustomQuestionType);
                                            }
                                        }}
                                    >
                                        <CreateEditCustomQuestion
                                            isNew
                                            data={newCustomQuestion}
                                            setData={setNewCustomQuestion}
                                            validator={customQuestionValidator}
                                        />
                                    </DotLegalDialog>
                                </>
                            )}
                            {permissions.riskTemplatePermissions.edit && editQuestionOpen && customQuestionToEdit ? (
                                <>
                                    <DotLegalDialog
                                        header={translateString("editCustomQuestionDialogHeader")}
                                        buttonOkText={translateString("save")}
                                        showDeleteButton={showDeleteButton}
                                        deleteButtonText={translateString("delete")}
                                        onDeleteClick={() => {
                                            setCustomQuestionToDelete(customQuestionToEdit);
                                            setDeleteDialogOpen(true);
                                        }}
                                        deleteButtonDisabled={customQuestionToEdit.inUse}
                                        deleteButtonTooltip={customQuestionToEdit.inUse ? translateString("deleteTooltipCustomQuestionInUse") : ""}
                                        size={"md"}
                                        open={editQuestionOpen}
                                        onDialogClose={() => {
                                            setEditQuestionOpen(false);
                                            setCustomQuestionToEdit(undefined);
                                            setCustomQuestion(undefined);
                                        }}
                                        onOkClick={async () => {
                                            const saveResult = await onSaveCustomQuestion(true);
                                            if (saveResult) {
                                                setEditQuestionOpen(false);
                                                setCustomQuestionToEdit(undefined);
                                                setCustomQuestion(undefined);
                                            }
                                        }}
                                    >
                                        <CreateEditCustomQuestion
                                            isNew={false}
                                            data={customQuestion}
                                            setData={setCustomQuestion}
                                            validator={customQuestionValidator}
                                        />
                                    </DotLegalDialog>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}

                    {riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.GDPR ? (
                        categories.map((c) => {
                            const displayedQuestions = questions
                                .filter((q) => c.questionTypes.some((qt) => qt === q.questionType))
                                .map((q) => GetQuestionBox(q));
                            return (
                                <React.Fragment key={c.questionCategory}>
                                    {displayedQuestions.length > 0 ? (
                                        <>
                                            <DotLegalHeader headerStyle={"small"}>
                                                {translateString(`questionCategory${QuestionCategory[c.questionCategory]}`)}
                                            </DotLegalHeader>
                                            {displayedQuestions}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <React.Fragment>{questions.map((q) => GetQuestionBox(q))}</React.Fragment>
                    )}

                    {customQuestionToDelete ? (
                        <DotLegalDeleteDialogWrapper
                            itemForDeletion={customQuestionToDelete.name ?? customQuestionToDelete.customQuestionId}
                            open={deleteDialogOpen}
                            onDialogClose={() => setDeleteDialogOpen(false)}
                            onOkClick={async () => {
                                if (customQuestionToDelete?.customQuestionId) {
                                    const result = await onDeleteCustomQuestion(customQuestionToDelete.customQuestionId);
                                    if (result) {
                                        setDeleteDialogOpen(false);
                                        setEditQuestionOpen(false);
                                    }
                                }
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </React.Fragment>
            );
        },
        [
            translateString,
            permissions.riskTemplatePermissions.create,
            permissions.riskTemplatePermissions.edit,
            createQuestionOpen,
            newCustomQuestion,
            setNewCustomQuestion,
            customQuestionValidator,
            customQuestionToEdit,
            showDeleteButton,
            editQuestionOpen,
            customQuestion,
            setCustomQuestion,
            customQuestionToDelete,
            deleteDialogOpen,
            onSaveCustomQuestion,
            GetQuestionBox,
            onDeleteCustomQuestion,
            riskAssessmentArea,
            riskComplianceArea,
            isLocked,
        ]
    );

    const getHeader = useCallback(
        (value: string, tooltip: string) => (
            <Box sx={styles.questionText}>
                {value}
                <Box sx={styles.tooltip}>
                    <DotLegalInformationTooltip text={tooltip} />
                </Box>
            </Box>
        ),
        [styles.questionText, styles.tooltip]
    );
    const getSelectedTemplate = useCallback(
        (templates: Array<RiskAssessmentTemplateItemViewModel>) => {
            let selectedItems = templates.filter((x) => x.selected);
            return (
                <React.Fragment>
                    <DotLegalHeader headerStyle={"medium"}>
                        {translateString("yourRiskTemplate")} - {riskType}
                    </DotLegalHeader>
                    {selectedItems.length === 0 ? (
                        <Box sx={styles.emptyStateContainer}>
                            <DotLegalEmptyState
                                icon={<EmptyTemplateState />}
                                text={selectedTab === classificationTab ? translateString("noRiskScenariosChosen") : translateString("noRiskQuestionChosen")}
                            />
                        </Box>
                    ) : (
                        <TransitionGroup sx={styles.templateContainer}>
                            {selectedItems.map((x) => {
                                const nodeRef = createRef<HTMLElement>();
                                return (
                                    <CSSTransition
                                        key={x.key()}
                                        nodeRef={nodeRef}
                                        timeout={500}
                                        classNames={{
                                            enter: "itemEnter",
                                            enterActive: "itemEnterActive",
                                            exit: "itemExit",
                                            exitActive: "itemExitActive",
                                        }}
                                    >
                                        <RiskAssessmentTemplateCollapse
                                            componentKey={expandedItem}
                                            businessAreas={businessAreas!}
                                            header={getHeader(x.name, "")}
                                            onScoreChange={(type: RiskAssessmentTemplateItemType, score: number | null) => onScoreChange(x, type, score)}
                                            score={x.score}
                                            probabilityScore={x.probabilityScore}
                                            consequenceScore={x.consequenceScore}
                                            selectedBusinessAreas={x.businessAreas}
                                            onBusinessAreaToggled={(businessAreaId?: string, resetBusinessAreas?: boolean) =>
                                                onBusinessAreaToggle(x, businessAreaId, resetBusinessAreas)
                                            }
                                            onExpandToggle={() => onExpandToggle(x.key())}
                                            expandedKey={x.key()}
                                            isQuestion={x.itemType !== RiskAssessmentTemplateItemType.Scenario}
                                            riskAssessmentArea={riskAssessmentArea}
                                            isDisabled={isLocked}
                                        />
                                    </CSSTransition>
                                );
                            })}
                        </TransitionGroup>
                    )}
                </React.Fragment>
            );
        },
        [
            businessAreas,
            expandedItem,
            getHeader,
            onBusinessAreaToggle,
            onExpandToggle,
            onScoreChange,
            riskType,
            selectedTab,
            styles.emptyStateContainer,
            styles.templateContainer,
            translateString,
            riskAssessmentArea,
            isLocked,
        ]
    );

    const getTabContent = useMemo(() => {
        const content = Array<TabContent>();

        content.push({
            label: translateString("questions"),
            content: getProbabilityContent(items, categories, translateString("chooseYourQuestions")),
            index: 0,
        });
        content.push({
            label: translateString("customQuestions"),
            content: getProbabilityContent(items, categories, translateString("customQuestionsHeader"), true),
            index: 1,
        });

        if (riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.GDPR) {
            content.push({
                label: translateString("riskClassification"),
                content: getContent(items, translateString("chooseYourScenarios"), translateString("templateScenarioTooltip")),
                index: 2,
            });
        }

        return content;
    }, [categories, getContent, getProbabilityContent, items, translateString, riskAssessmentArea, riskComplianceArea]);

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs()} />

            {isLoading ? (
                <DotLegalFullScreenSpinner />
            ) : (
                <Grid sx={styles.root} container spacing={2}>
                    <Grid item lg={4} md={6} sm={12}>
                        <DotLegalPaper fullHeight removePadding>
                            <DotLegalTabs
                                fullWidth
                                tabStyle="primary"
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                content={getTabContent}
                                ignoreTextTransformation
                            />
                        </DotLegalPaper>
                    </Grid>
                    <Grid item lg={8} md={6} sm={12}>
                        <DotLegalPaper fullHeight>{getSelectedTemplate(items)}</DotLegalPaper>
                    </Grid>
                </Grid>
            )}
            {itemForRemoval && (
                <DotLegalInfoDialog
                    header={translateString("warning")}
                    btnText={translateString("remove")}
                    content={createElement(Trans, { i18nKey: "riskTemplateItemInUserWarning", values: { name: itemForRemoval.name } })}
                    onCloseDialog={onWarningDialogClose}
                    onBtnClick={() => onTemplateItemToggle(itemForRemoval)}
                />
            )}
        </React.Fragment>
    );
}

export default RiskAssessmentTemplate;
