import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { DotLegalInformationTooltip, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { System, SystemType } from "../SystemsStep.types";
import { useSystemSelectorStyles } from "./SystemSelector.styles";
import { useUrlProvider } from "../../../useUrlProvider";
import DotLegalSelectWithButton from "../../../common/components/dotLegalSelectWithButton/DotLegalSelectWithButton";
import { useSystemSelectorHook } from "./SystemSelector.hooks";
import AddSystemDialog from "../../../system/addSystemDialog/AddSystemDialog";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";

export interface ISystemSelector {
    onSystemChange: (systemId: string | undefined) => void;
    systems?: Array<System>;
    chosenSystemId?: string;
    isLoading: boolean;
    readOnly?: boolean;
    onSystemCreated: (id: string) => void;
}

function SystemSelector(props: ISystemSelector) {
    const { getSystemOverviewUrl } = useUrlProvider();
    const { translateString } = useTranslation();
    const styles = useSystemSelectorStyles();
    const { permissions } = useUserContext();
    const { setShowAddDialog, showAddDialog, searchValue, setSearchValue } = useSystemSelectorHook();
    const system = props.systems?.find((x) => x.id === props.chosenSystemId);

    return (
        <React.Fragment>
            <Box sx={styles.systemContainer}>
                <DotLegalSelectWithButton
                    link={system ? getSystemOverviewUrl(system.id) : undefined}
                    label={translateString("system")}
                    placeholder={translateString("system")}
                    options={
                        props.systems
                            ? props.systems.map((obj) => {
                                  return {
                                      id: obj.id,
                                      name: obj.name,
                                  };
                              })
                            : undefined
                    }
                    onChange={(sys) => props.onSystemChange(sys ? sys : undefined)}
                    isLoading={props.isLoading}
                    selectedItem={system ? system.id : undefined}
                    toolTipText={translateString("systemHelperText")}
                    disableClearable
                    disabled={props.readOnly}
                    noOptionsLabel={translateString("noOptions")}
                    onButtonClick={() => setShowAddDialog(true)}
                    hasPermission={permissions.systemPermissions.create && !props.readOnly}
                    buttonLabel={translateString("createNew")}
                    searchValue={searchValue}
                    onSearchValueChange={setSearchValue}
                />
            </Box>
            {system && (
                <Paper elevation={0} sx={styles.paper}>
                    <Box sx={styles.container}>
                        <Box sx={styles.flex}>
                            <Box sx={styles.toolTip}>
                                <DotLegalInformationTooltip text={translateString("systemDataProcessorHelperText")} />
                            </Box>
                            <Typography sx={styles.primaryText}> {translateString("dataProcessors")}:</Typography>&nbsp;
                        </Box>
                        <Typography sx={styles.secondaryText}>{system.dataProcessors.join(", ")}</Typography>
                    </Box>
                    <Box sx={styles.container}>
                        <Box sx={styles.flex}>
                            <Box sx={styles.toolTip}>
                                <DotLegalInformationTooltip text={translateString("securitymeasuresHelperText")} />
                            </Box>
                            <Typography sx={styles.primaryText}> {translateString("securityMeasures")}:</Typography>&nbsp;
                        </Box>
                        <Typography sx={styles.secondaryText}>{system!.securityMeasureNames ? system!.securityMeasureNames.join(", ") : ""}</Typography>
                    </Box>
                    <Box sx={styles.container}>
                        <Box sx={styles.flex}>
                            <Box sx={styles.toolTip}>
                                <DotLegalInformationTooltip text={translateString("systemTypeHelperText")} />
                            </Box>
                            <Typography sx={styles.primaryText}> {translateString("systemType")}:</Typography>&nbsp;
                        </Box>
                        <Typography sx={styles.secondaryText}>
                            {translateString(SystemType[Number(system.systemType)].toLocaleString().toLowerCase())}
                        </Typography>
                    </Box>
                    <Box sx={styles.container}>
                        <Box sx={styles.flex}>
                            <Box sx={styles.toolTip}>
                                <DotLegalInformationTooltip text={translateString("responsibleHelperText")} />
                            </Box>
                            <Typography sx={styles.primaryText}> {translateString("responsible")}:</Typography>&nbsp;
                        </Box>
                        <Typography sx={styles.secondaryText}>{system.responsibleName}</Typography>
                    </Box>
                </Paper>
            )}

            {showAddDialog && (
                <AddSystemDialog
                    showDialog
                    onHideDialog={() => setShowAddDialog(false)}
                    searchedName={searchValue}
                    onSystemCreated={(id) => {
                        setShowAddDialog(true);
                        props.onSystemCreated(id);
                    }}
                />
            )}
        </React.Fragment>
    );
}

export default SystemSelector;
