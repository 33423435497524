import { useQuery } from "react-query";
import { LegalEntityClassificationApiModel } from "./LegalEntityClassificationTab.types";
import { get } from "../../common/api/apiShared";
import { ILegalEntityClassificationTab } from "./LegalEntityClassificationTab";
import { useState } from "react";

export default function useLegalEntityClassificationTabHooks(props: ILegalEntityClassificationTab) {
    const url = `/LegalEntityClassification/${props.legalEntityId}`;
    let { isLoading, data, refetch } = useQuery(url, () => get<LegalEntityClassificationApiModel>(url));

    const [showClassificationDialog, setShowClassificationDialog] = useState(false);

    function onClassificationCreated() {
        refetch();
        setShowClassificationDialog(false);
    }

    return {
        isLoading,
        data,
        refetch,
        onClassificationCreated,
        showClassificationDialog,
        setShowClassificationDialog,
    };
}
