import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HelpTextType, HelpTextViewModel } from "../../../masterData/helpText/helpTexts/HelpTexts.types";
import { StepTypeEnum } from "../../../processingActivity/ProcessingActivity.types";
import { useUrlParams } from "../../hooks/useUrlParams";
import { StepModel } from "./DotLegalCoreStepper.types";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { useTranslation } from "../../../localization/useTranslation";

export function useSteps(
    model: any | undefined,
    steps: Array<StepModel>,
    stepName: "step" | "substep",
    hasDataAdded: (step: number) => boolean,
    getHoverData?: (step: number) => { hoverContent: any; toolTipMaxWidth: number } | undefined,
    getIsStepUserValidated?: (type: StepTypeEnum) => { validationId: string; isValidated: boolean } | undefined,
    helpTexts?: Array<HelpTextViewModel>
) {
    const navigate = useNavigate();
    const { processingActivityAndRecordsPlan, proceduresAndPoliciesPlan, vendorManagementPlan, ismsPlanType, systemPlan } = usePlanContext();
    const { translateString } = useTranslation();
    const location = useLocation();
    const urlParams = useUrlParams();
    const [visistedSteps, setVisitedSteps] = useState<Array<number>>([]);
    const validateImmediately = !location.search.includes("create=true");

    function changeStep(step: number) {
        markCurrentStepAsVisited();
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(stepName, step.valueOf().toString());
        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    function getLegalEntityManagementSteps() {
        return steps.map((s) => {
            const isVisited = IsStepVisited(s);
            const isDisabled = (s.stepType === 2 && !vendorManagementPlan.hasAccess) || (s.stepType === 3 && !ismsPlanType && !vendorManagementPlan.hasAccess);
            return {
                name: s.name,
                error: s.errors(model, validateImmediately, isVisited).length > 0,
                visited: hasDataAdded(s.stepType),
                stepType: s.stepType,
                disabled: isDisabled,
                disabledToolTip: isDisabled ? translateString("upgradePrivacyPlanToday") : undefined,
                stepHover: undefined,
                stepValidated: undefined,
                helpTextHeader: undefined,
                helpTextContent: undefined,
            };
        });
    }

    function getSystemSteps() {
        return steps.map((s) => {
            const isVisited = IsStepVisited(s);
            const isDisabled =
                (s.stepType === 5 && !ismsPlanType) || (s.stepType === 6 && !systemPlan.associatedAssets) || (s.stepType === 8 && !systemPlan.personalData);
            return {
                name: s.name,
                error: s.errors(model, validateImmediately, isVisited).length > 0,
                visited: hasDataAdded(s.stepType),
                stepType: s.stepType,
                disabled: isDisabled,
                disabledToolTip: isDisabled ? translateString("upgradePrivacyPlanToday") : undefined,
                stepHover: undefined,
                stepValidated: undefined,
                helpTextHeader: undefined,
                helpTextContent: undefined,
            };
        });
    }

    function getSteps() {
        return steps.map((s, index) => {
            const isVisited = IsStepVisited(s);
            const helpText = getHelpTextByStepType(s.stepType);
            const isDisabled =
                (s.stepType === StepTypeEnum.dataController && !processingActivityAndRecordsPlan.registerSelfAsDataProcessor) ||
                (s.stepType === StepTypeEnum.policies && !proceduresAndPoliciesPlan.processingActivityAndPolicyLink);
            return {
                name: s.name,
                error: s.errors(model, validateImmediately, isVisited).length > 0,
                visited: hasDataAdded(s.stepType),
                stepType: s.stepType,
                stepHover: getHoverData ? getHoverData(s.stepType) : undefined,
                stepValidated: getIsStepUserValidated ? getIsStepUserValidated(s.stepType) : undefined,
                helpTextHeader: helpText?.header,
                helpTextContent: helpText?.content,
                disabled: isDisabled,
                disabledToolTip: isDisabled
                    ? s.stepType === StepTypeEnum.dataController
                        ? translateString("upgradePrivacyPlanToday")
                        : translateString("upgradePrivacyPlanTodayToRegisterPoliciesOnProceessingActivities")
                    : undefined,
            };
        });
    }

    function getHelpTextByStepType(stepType: StepTypeEnum) {
        var mappedStepType = MapStepTypeToHelpTextType(stepType);

        return helpTexts?.find((x) => x.type === mappedStepType);
    }

    function MapStepTypeToHelpTextType(stepType: StepTypeEnum) {
        switch (stepType) {
            case StepTypeEnum.association:
                return HelpTextType.ProcessingActivityAssociation;
            case StepTypeEnum.dataCategories:
                return HelpTextType.ProcessingActivityDataCategory;
            case StepTypeEnum.masterData:
                return HelpTextType.ProcessingActivityMasterData;
            case StepTypeEnum.dataSubject:
                return HelpTextType.ProcessingActivityDataSubject;
            case StepTypeEnum.legalBases:
                return HelpTextType.ProcessingActivityLegalBasis;
            case StepTypeEnum.policies:
                return HelpTextType.ProcessingActivityPolicy;
            case StepTypeEnum.sharings:
                return HelpTextType.ProcessingActivitySharing;
            case StepTypeEnum.systems:
                return HelpTextType.ProcessingActivitySystem;
            default:
                return undefined;
        }
    }

    function IsStepVisited(stepModel: StepModel) {
        const stepType = stepModel.stepType;

        if (!validateImmediately) return visistedSteps.some((v) => v === stepType);
        else {
            return hasDataAdded(stepType);
        }
    }

    const activeStep = steps.find((s) => s.stepType === getActiveStep().stepType)!;

    function markCurrentStepAsVisited() {
        if (!visistedSteps.some((v) => v === activeStep.stepType)) {
            visistedSteps.push(activeStep.stepType);
        }
        setVisitedSteps([...visistedSteps]);
    }

    function getActiveStep() {
        const step = urlParams.getUrlParams.getValue(stepName);
        let stepsByPermission = getSteps();
        if (stepsByPermission.length === 0) {
            return steps[0];
        }
        if (step) {
            return stepsByPermission.find((s) => s.stepType === parseInt(step))!;
        }
        return stepsByPermission[0];
    }

    return {
        getSystemSteps,
        getLegalEntityManagementSteps,
        getSteps,
        getActiveStep,
        changeStep,
        validationErrors: activeStep?.errors(model, validateImmediately, visistedSteps.find((v) => v === activeStep.stepType) === activeStep.stepType) ?? [],
    };
}
