import {
    DotLegalButton,
    DotLegalDeleteDialog,
    DotLegalEmptyState,
    DotLegalOverflowMenuItem,
    DotLegalPaper,
    DotLegalTable,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box } from "@mui/material";
import InternalAssessmentTable from "../../../common/components/questionnaireComponents/internalAssessmentTable/InternalAssessmentTable";
import NoSharings from "../../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import useSystemInternalAssessmentTabHooks from "./SystemAssociatedInternalAssessmentTab.hooks";
import SystemAssociatedInternalAssessmentDialog from "./systemAssociatedInternalAssessmentDialog/SystemAssociatedInternalAssessmentDialog";
import { InternalAssessmentApiRow } from "../../../internalAssessment/InternalAssessments.types";
import DotLegalNoteDialog from "../../../common/components/dotLegalNoteDialog/DotLegalNoteDialog";

export interface ISystemInternalAssessmentTabProps {
    systemId: string;
    systemName: string;
}

function SystemAssociatedInternalAssessmentTab(props: ISystemInternalAssessmentTabProps) {
    const { translateString } = useTranslation();
    const {
        showAddDialog,
        setShowAddDialog,
        data,
        isLoading,
        refetch,
        setShowDeleteDialog,
        showDeleteDialog,
        setSelectedInternalAssessment,
        removeAssociatedInternalAssessment,
        isRemoving,
        setShowNote,
        showNote,
        selectedInternalAssessment,
    } = useSystemInternalAssessmentTabHooks(props);

    return (
        <>
            <DotLegalPaper>
                <InternalAssessmentTable
                    internalAssessmentModel={data}
                    isLoading={isLoading}
                    readOnly
                    emptyState={<DotLegalEmptyState icon={<NoSharings />} text={translateString("noAssociatedInternalAssessments")} />}
                    getMenuItems={(row) => [
                        {
                            menuItem: translateString("removeAssociation"),
                            onClick: () => {
                                setSelectedInternalAssessment(row);
                                setShowDeleteDialog(true);
                            },
                        },
                    ]}
                    onNoteClick={(row) => {
                        setSelectedInternalAssessment(row);
                        setShowNote(true);
                    }}
                />

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <DotLegalButton onClick={() => setShowAddDialog(true)} buttonType={"primary"}>
                        {translateString("associateAssessmentToSystem")}
                    </DotLegalButton>
                </Box>

                {showAddDialog && (
                    <SystemAssociatedInternalAssessmentDialog
                        systemName={props.systemName}
                        systemId={props.systemId}
                        onDialogClose={() => {
                            refetch();
                            setShowAddDialog(false);
                        }}
                        existingInternalAssessments={data ? data?.rows?.map((x) => x.id) : []}
                    />
                )}

                {showDeleteDialog && (
                    <DotLegalDeleteDialog
                        open
                        headerText={translateString("removeAssociation")}
                        warningTranslation={""}
                        deleteBtnText={translateString("remove")}
                        cancelBtnText={translateString("cancel")}
                        onDialogClose={() => setShowDeleteDialog(false)}
                        onOkClick={() => removeAssociatedInternalAssessment()}
                        alertBodyText={translateString("removeInternalAssessmentToSystem")}
                        inProgress={isRemoving}
                    />
                )}

                {showNote && (
                    <DotLegalNoteDialog
                        noteText={selectedInternalAssessment?.note}
                        onDialogClose={() => setShowNote(false)}
                        onOkClick={() => {}}
                        disabled
                        isSaving={false}
                        showDialog
                    />
                )}
            </DotLegalPaper>
        </>
    );
}

export default SystemAssociatedInternalAssessmentTab;
