import {
    DotLegalButton,
    DotLegalDeleteDialog,
    DotLegalEmptyState,
    DotLegalInfoDialog,
    DotLegalOverflowMenuItem,
    DotLegalPaper,
    DotLegalTable,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import useProcessingActivityInternalAssessmentTabHooks from "./ProcessingActivityAssociatedInternalAssessmentTab.hooks";
import { useTranslation } from "../../../localization/useTranslation";
import ProcessingActivityAssociatedInternalAssessmentDialog from "./processingActivityAssociatedInternalAssessmentDialog/ProcessingActivityAssociatedInternalAssessmentDialog";
import { Box } from "@mui/material";
import InternalAssessmentTable from "../../../common/components/questionnaireComponents/internalAssessmentTable/InternalAssessmentTable";
import NoSharings from "../../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { InternalAssessmentApiRow } from "../../../internalAssessment/InternalAssessments.types";
import DotLegalNoteDialog from "../../../common/components/dotLegalNoteDialog/DotLegalNoteDialog";

export interface IProcessingActivityInternalAssessmentTabProps {
    processingActivityId: string;
    processingActivityName: string;
}

function ProcessingActivityAssociatedInternalAssessmentTab(props: IProcessingActivityInternalAssessmentTabProps) {
    const { translateString } = useTranslation();
    const {
        showAddDialog,
        setShowAddDialog,
        data,
        isLoading,
        refetch,
        setShowDeleteDialog,
        showDeleteDialog,
        setSelectedInternalAssessment,
        removeAssociatedInternalAssessment,
        isRemoving,
        setShowNote,
        showNote,
        selectedInternalAssessment,
    } = useProcessingActivityInternalAssessmentTabHooks(props);

    return (
        <>
            <DotLegalPaper>
                <InternalAssessmentTable
                    internalAssessmentModel={data}
                    isLoading={isLoading}
                    readOnly
                    emptyState={<DotLegalEmptyState icon={<NoSharings />} text={translateString("noAssociatedInternalAssessments")} />}
                    getMenuItems={(row) => [
                        {
                            menuItem: translateString("removeAssociation"),
                            onClick: () => {
                                setSelectedInternalAssessment(row);
                                setShowDeleteDialog(true);
                            },
                        },
                    ]}
                    onNoteClick={(row) => {
                        setSelectedInternalAssessment(row);
                        setShowNote(true);
                    }}
                />

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <DotLegalButton onClick={() => setShowAddDialog(true)} buttonType={"primary"}>
                        {translateString("associateAssessmentToProcessingActivity")}
                    </DotLegalButton>
                </Box>

                {showAddDialog && (
                    <ProcessingActivityAssociatedInternalAssessmentDialog
                        processingActivityId={props.processingActivityId}
                        processingActivityName={props.processingActivityName}
                        onDialogClose={() => {
                            refetch();
                            setShowAddDialog(false);
                        }}
                        existingInternalAssessments={data ? data?.rows?.map((x) => x.id) : []}
                    />
                )}

                {showDeleteDialog && (
                    <DotLegalDeleteDialog
                        open
                        headerText={translateString("removeAssociation")}
                        warningTranslation={""}
                        deleteBtnText={translateString("remove")}
                        cancelBtnText={translateString("cancel")}
                        onDialogClose={() => setShowDeleteDialog(false)}
                        onOkClick={() => removeAssociatedInternalAssessment()}
                        alertBodyText={translateString("removeInternalAssessmentToProcessingActivity")}
                        inProgress={isRemoving}
                    />
                )}

                {showNote && (
                    <DotLegalNoteDialog
                        noteText={selectedInternalAssessment?.note}
                        onDialogClose={() => setShowNote(false)}
                        onOkClick={() => {}}
                        disabled
                        isSaving={false}
                        showDialog
                    />
                )}
            </DotLegalPaper>
        </>
    );
}

export default ProcessingActivityAssociatedInternalAssessmentTab;
