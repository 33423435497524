import { createElement, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { get, post, put } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { TrackingEvent, useTrackAIEvent } from "../../processingActivity/hooks/useTracking";
import { AnnualWheelMonthEnum, ApprovalOption, DialogStep, TabStep } from "../AnnualWheel.types";
import { IAnnualWheelDialog } from "./AnnualWheelDialog";
import {
    AnnualWheelActivityCategoryType,
    AnnualWheelActivityFramework,
    AnnualWheelActivityModel,
    AnnualWheelActivitySaveModel,
    AnnualWheelActivityTemplateItem,
    AnnualWheelActivityType,
    AnnualWheelFilterOption,
    AnnualWheelPriority,
    NIS2TemplateContainer,
    StandardAnnualWheelActivityCategory,
} from "./AnnualWheelDialog.types";
import { AnnualWheelResponsibleFormModel } from "./annualWheelResponsibleForm/AnnualWheelResponsibleForm.types";
import { useAnnualWheelActivityTemplateDataMapping } from "../AnnualWheelActivityTemplate/AnnualWheelActivityTemplate.hooks";
import { AnnualWheelDurationFormModel } from "./annualWheelDurationForm/AnnualWheelDurationForm.types";
import { AnnualWheelBaseInformationFormModel } from "./annualWheelBaseInformationForm/AnnualWheelBaseInformationForm.types";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { ISOCategory, ISOCategoryMapper } from "./templates/isoTemplates/IsoTemplates.types";

export function useAnnualWheelDialogDataMapping(props: IAnnualWheelDialog) {
    const {
        setTemplateModel,
        templateModel,
        saveTemplate,
        validateName,
        templateValidation,
        isEdit: isEditingTemplate,
        updateActiveTemplate,
        inProgress: isTemplateSaveInProgress,
        copyTemplate,
        isTemplateValid,
        standardActivityCopy,
        setStandardActivityCopy,
    } = useAnnualWheelActivityTemplateDataMapping(onCopyComplete, onTemplateCreated);

    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();
    const [dialogStep, setDialogStep] = useState<DialogStep>(getDefaultDialogStep(props.activityId, props.templateId));
    const [selectedTab, setSelectedTab] = useState<TabStep>(TabStep.GDPRTemplates);
    const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    const [selectedActivity, setSelectedActivity] = useState("");
    const [selectedExistingActivity, setSelectedExistingActivity] = useState({ activityId: props.activityId, templateId: props.templateId });

    const [selectedFilterOption, setSelectedFilterOption] = useState(AnnualWheelFilterOption.All.toString());
    const [showFormError, setShowFormError] = useState<boolean>(false);
    const [annualWheelDurationFormModel, setAnnualWheelDurationFormModel] = useState<AnnualWheelDurationFormModel>(
        new AnnualWheelDurationFormModel(null, undefined, undefined, undefined, false)
    );
    const [annualWheelResponsibleFormModel, setAnnualWheelResponsibleFormModel] = useState<AnnualWheelResponsibleFormModel>(
        new AnnualWheelResponsibleFormModel([], "", [], ApprovalOption.ApprovalNotRequired, [])
    );
    const [annualWheelBaseInformationFormModel, setAnnualWheelBaseInformationFormModel] = useState<AnnualWheelBaseInformationFormModel>(
        new AnnualWheelBaseInformationFormModel(undefined, null, undefined, undefined, undefined, undefined)
    );

    const trackEvent = useTrackAIEvent();
    const url = "/annualWheelActivity/";

    const { isLoading, data: annulWheelActivityData } = useQuery(
        url + selectedExistingActivity.activityId,
        () => get<AnnualWheelActivityModel>(url + selectedExistingActivity.activityId),
        {
            enabled: selectedExistingActivity.activityId !== undefined && !selectedActivity,
        }
    );

    const stdAnnualWheelActivitiesUrl = "/annualWheelActivityTemplate";
    const annualWheelActivityTemplatesQuery = useQuery(stdAnnualWheelActivitiesUrl, () =>
        get<Array<AnnualWheelActivityTemplateItem>>(stdAnnualWheelActivitiesUrl)
    );

    let stdActivitiesLoading = annualWheelActivityTemplatesQuery.isLoading;

    let allActivities: Array<AnnualWheelActivityTemplateItem> = useMemo(() => {
        return annualWheelActivityTemplatesQuery.data ?? [];
    }, [annualWheelActivityTemplatesQuery.data]);

    useEffect(() => {
        if (annulWheelActivityData) {
            setSelectedActivity(annulWheelActivityData.id!);

            if (selectedExistingActivity.templateId && selectedExistingActivity.activityId) {
                setTemplateModel({
                    description: annulWheelActivityData?.description ?? "",
                    subTasks: annulWheelActivityData?.subTasks ?? [],
                    id: annulWheelActivityData?.id ?? "",
                    name: annulWheelActivityData?.name ?? "",
                    planActivity: false,
                });
            }

            let responsbileForm = new AnnualWheelResponsibleFormModel(
                annulWheelActivityData.businessAreas,
                annulWheelActivityData.responsible,
                annulWheelActivityData.groupCompanies,
                annulWheelActivityData.approval,
                annulWheelActivityData.assignees
            );
            setAnnualWheelResponsibleFormModel(responsbileForm);

            let durationForm = new AnnualWheelDurationFormModel(
                annulWheelActivityData.startDate,
                annulWheelActivityData.notificationPeriodInDays,
                annulWheelActivityData.interval,
                annulWheelActivityData.type,
                !!isEditActivity
            );
            setAnnualWheelDurationFormModel(durationForm);

            let baseInformation = new AnnualWheelBaseInformationFormModel(
                annulWheelActivityData.priority,
                annulWheelActivityData.customId,
                annulWheelActivityData.documentationRequired,
                annulWheelActivityData.areas,
                annulWheelActivityData.relatedActivities,
                undefined
            );
            setAnnualWheelBaseInformationFormModel(baseInformation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [annulWheelActivityData]);

    function save() {
        return post<{}>(url, mapToSaveModel());
    }

    const saveMutation = useMutation(save, {
        onSuccess: () => {
            props.onSave();
            props.onDialogClose();
            trackEvent(TrackingEvent.AnnualWheelActivityCreated, { annualWheelActivityType: selectedActivity });

            let activityName = allActivities?.find((x) => x.id === selectedActivity)?.name;
            snackbar.show(createElement(Trans, { i18nKey: "annualWheelActivityAdded", values: { name: activityName } }));
        },
    });

    const createActivity = async () => {
        await saveMutation.mutateAsync();
    };

    const editMutation = useMutation(update);
    const updateActivity = () => {
        editMutation.mutateAsync(selectedExistingActivity.activityId!, {
            onSuccess: () => {
                const activityName = getDialogHeader();
                snackbar.show(createElement(Trans, { i18nKey: "annualWheelActivityUpdated", values: { name: activityName } }));
                props.onSave();
                props.onDialogClose();
            },
        });
    };

    const isFinalStep = () => {
        return dialogStep === DialogStep.ActivityBaseInformation || dialogStep === DialogStep.CreateTemplate;
    };

    function update(id: string) {
        return put<{}>(`${url}${id}/`, mapToSaveModel());
    }

    function onSelectActivity(activity: AnnualWheelActivityTemplateItem) {
        setSelectedActivity(activity.id);
        setAnnualWheelResponsibleFormModel(new AnnualWheelResponsibleFormModel([], undefined, [], ApprovalOption.ApprovalNotRequired, []));
        setAnnualWheelBaseInformationFormModel(new AnnualWheelBaseInformationFormModel(undefined, null, undefined, undefined, undefined, undefined));
    }

    const onChangeTab = (step: TabStep) => {
        if (step !== selectedTab) {
            setSelectedActivity("");
        }

        setSelectedTab(step);
    };

    function onCopyComplete() {
        setSelectedActivity("");
        setDialogStep(DialogStep.CreateTemplate);
        refetch();
    }

    if (searchInput) {
        allActivities = allActivities.filter(
            (t) => t.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 || t.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1
        );
    }

    let gdprActivities = useMemo(() => {
        var standardActivitiesPerCategory: Array<StandardAnnualWheelActivityCategory> = [];

        allActivities
            .filter((a) => a.framework === AnnualWheelActivityFramework.GDPR && filterTemplatesByFilterOption(a))
            .forEach((activity) => {
                var categoryType = getCategory(activity.id);
                let standardActivity = standardActivitiesPerCategory.find((x) => x.categoryType === categoryType);

                if (standardActivity) {
                    standardActivity.templates.push(activity);
                } else {
                    standardActivitiesPerCategory.push({
                        categoryType: categoryType,
                        templates: new Array<AnnualWheelActivityTemplateItem>(activity),
                        framework: activity.framework!,
                    });
                }
            });
        return standardActivitiesPerCategory;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allActivities, selectedFilterOption]);

    let hasCustomActivities = allActivities.some((x) => x.isCustom);

    let customActiviites: AnnualWheelActivityTemplateItem[] = useMemo(() => {
        return allActivities.filter((x) => x.isCustom && filterTemplatesByFilterOption(x));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allActivities, selectedFilterOption]);

    let nis2Templates: NIS2TemplateContainer = useMemo(() => {
        var templates = new NIS2TemplateContainer();
        templates.setTemplates(allActivities.filter((x) => x.framework === AnnualWheelActivityFramework.NIS2 && filterTemplatesByFilterOption(x)));

        return templates;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allActivities, selectedFilterOption]);

    let isoTemplates: { iso27001Templates: ISOCategory[]; iso27002Templates: ISOCategory[]; iso27002_2013Templates: ISOCategory[] } = useMemo(() => {
        var isoTemplatemapper = new ISOCategoryMapper();
        isoTemplatemapper.setTemplates(
            allActivities.filter(
                (x) =>
                    (x.framework === AnnualWheelActivityFramework.ISO27001 || x.framework === AnnualWheelActivityFramework.ISO27002) &&
                    filterTemplatesByFilterOption(x)
            )
        );
        return {
            iso27001Templates: isoTemplatemapper.iso27001Templates,
            iso27002Templates: isoTemplatemapper.iso27002Templates,
            iso27002_2013Templates: isoTemplatemapper.iso27002_2013Templates,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allActivities, selectedFilterOption]);

    const isCustomActivity = () => {
        if (!selectedActivity) return false;
        return isNaN(Number(selectedActivity));
    };

    const isEditActivity = selectedExistingActivity.activityId && dialogStep !== DialogStep.CreateTemplate;
    const getPreviousBtnText = () => {
        return dialogStep === DialogStep.ActivitySelection ||
            (isEditActivity && !selectedExistingActivity.templateId && dialogStep === DialogStep.ActivityDurationData) ||
            (isEditActivity && selectedExistingActivity.templateId && dialogStep === DialogStep.CustomActivityEditFrom)
            ? ""
            : dialogStep === DialogStep.CreateTemplate
              ? translateString("cancel")
              : translateString("previous");
    };

    const getOkBtnText = () => {
        if (!isFinalStep()) {
            return translateString("next");
        }

        if (isEditActivity) {
            return translateString("save");
        }

        if (dialogStep === DialogStep.CreateTemplate) {
            if (isEditingTemplate) {
                return translateString("save");
            } else {
                return translateString("create");
            }
        }
        return translateString("add");
    };

    function getDialogHeader() {
        if (selectedActivity && dialogStep! !== DialogStep.ActivitySelection) {
            if (annulWheelActivityData?.name) {
                return annulWheelActivityData.name;
            }

            let activity = allActivities!.find((x) => x.id === selectedActivity);
            return activity?.name;
        } else if (dialogStep === DialogStep.CreateTemplate) {
            if (isEditingTemplate) {
                return translateString("editActivity");
            } else {
                return translateString("createActivity");
            }
        }

        return "";
    }

    const onCopyClick = (activity: AnnualWheelActivityTemplateItem) => {
        setDialogStep(DialogStep.CreateTemplate);
        setTemplateModel({
            id: "",
            description: activity.description,
            name: `${activity.name} ${translateString("copySingular")}`,
            planActivity: true,
            subTasks: activity.subTasks,
        });
        setStandardActivityCopy(activity);
        setSelectedExistingActivity({ activityId: activity.annaulWheelActivityId ?? undefined, templateId: activity.templateId ?? undefined });
    };

    return {
        isLoading: isLoading || stdActivitiesLoading,
        inProgress: isTemplateSaveInProgress || saveMutation.isLoading || editMutation.isLoading,
        selectedActivity,
        setSelectedActivity,
        dialogStep,
        setDialogStep,
        searchInput,
        setSearchInput,
        gdprActivities,
        selectedTab,
        onChangeTab,
        customActiviites,
        hasCustomActivities,
        showDeleteTemplateDialog,
        setShowDeleteTemplateDialog,
        annualWheelResponsibleFormModel,
        setAnnualWheelResponsibleFormModel,
        isFinalStep,
        onOkClick,
        okButtonText: getOkBtnText(),
        previousButtonText: getPreviousBtnText(),
        dialogHeaderText: getDialogHeader(),
        onPreviousButtonClick,
        setTemplateModel,
        templateModel,
        validateName,
        templateValidation,
        refetch,
        annualWheelDurationFormModel,
        setAnnualWheelDurationFormModel,
        showFormError,
        isCustomActivity,
        nis2Templates,
        isoTemplates,
        copyTemplate,
        annualWheelBaseInformationFormModel,
        setAnnualWheelBaseInformationFormModel,
        onSelectActivity,
        selectedFilterOption,
        setSelectedFilterOption,
        setStandardActivityCopy,
        onCopyClick,
    };

    function refetch() {
        annualWheelActivityTemplatesQuery.refetch();
    }

    function onTemplateCreated(id: string, planActivity: boolean, standardActivityCopy?: AnnualWheelActivityTemplateItem) {
        refetch();

        if (planActivity) {
            setSelectedActivity(id);

            if (!selectedExistingActivity.activityId) {
                setAnnualWheelDurationFormModel(
                    new AnnualWheelDurationFormModel(
                        getStartDate(props.selectedMonth, props.selectedYear),
                        standardActivityCopy?.notificationPeriodInDays ?? 14,
                        // null means that activity should only be done once
                        standardActivityCopy?.recurringIntervalInMonths === null ? null : (standardActivityCopy?.recurringIntervalInMonths ?? 0),
                        AnnualWheelActivityType.Custom,
                        !!isEditActivity
                    )
                );
                setAnnualWheelBaseInformationFormModel(
                    new AnnualWheelBaseInformationFormModel(
                        standardActivityCopy?.priority ?? AnnualWheelPriority.Moderate,
                        null,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        standardActivityCopy?.framework
                    )
                );
            }

            setDialogStep(DialogStep.ActivityDurationData);
        } else {
            setDialogStep(DialogStep.ActivitySelection);
        }
    }

    function mapToSaveModel() {
        const saveModel: AnnualWheelActivitySaveModel = {
            businessAreas: annualWheelResponsibleFormModel?.businessAreas,
            documentationRequired: annualWheelBaseInformationFormModel?.documentationRequired,
            interval: annualWheelDurationFormModel.interval,
            notificationPeriodInDays: annualWheelDurationFormModel.notificationPeriodInDays,
            priority: annualWheelBaseInformationFormModel.priority,
            responsible: isNullOrWhitespace(annualWheelResponsibleFormModel?.responsible) ? undefined : annualWheelResponsibleFormModel.responsible,
            startDate: annualWheelDurationFormModel.startDate,
            id: selectedActivity,
            areas: annualWheelBaseInformationFormModel.areas,
            customId: annualWheelBaseInformationFormModel.customId,
            groupCompanies: annualWheelResponsibleFormModel?.groupCompanies,
            relatedActivities: annualWheelBaseInformationFormModel.relatedActivities,
            approval: annualWheelResponsibleFormModel?.approval,
            assignees: annualWheelResponsibleFormModel.assignees,
            tags: annualWheelBaseInformationFormModel.tags,
        };

        return saveModel;
    }

    function getStartDate(selectedMonth: AnnualWheelMonthEnum | undefined, selectedYear: number | undefined) {
        var startDate = null;

        if (selectedMonth) {
            var month = selectedMonth - 1;
            startDate = new Date(selectedYear!, month, 15);
        }

        return startDate;
    }

    function onPreviousButtonClick() {
        switch (dialogStep) {
            case DialogStep.CreateTemplate: {
                setDialogStep(DialogStep.ActivitySelection);
                setStandardActivityCopy(undefined);
                setSelectedExistingActivity({ activityId: undefined, templateId: undefined });
                break;
            }
            case DialogStep.ActivityDurationData: {
                if (isCustomActivity() && isEditActivity) {
                    setDialogStep(DialogStep.CustomActivityEditFrom);
                } else {
                    setSelectedExistingActivity({ activityId: undefined, templateId: undefined });
                    setDialogStep(DialogStep.ActivitySelection);
                }
                break;
            }
            case DialogStep.ActivityResponsibleData: {
                setDialogStep(DialogStep.ActivityDurationData);
                break;
            }
            case DialogStep.ActivityBaseInformation: {
                if (selectedActivity === AnnualWheelActivityType.ProcessingActivityValidation.toString()) {
                    setDialogStep(DialogStep.ActivityDurationData);
                } else {
                    setDialogStep(DialogStep.ActivityResponsibleData);
                }

                break;
            }
        }
    }

    async function onOkClick() {
        switch (dialogStep) {
            case DialogStep.ActivitySelection: {
                let activity = allActivities.find((x) => x.id === selectedActivity);

                if (activity!.annaulWheelActivityId !== null && activity!.isScheduled) {
                    setSelectedExistingActivity({ activityId: activity?.annaulWheelActivityId, templateId: activity?.templateId ?? undefined });

                    if (!activity!.templateId) {
                        setDialogStep(DialogStep.ActivityDurationData);
                    } else {
                        setDialogStep(DialogStep.CustomActivityEditFrom);
                    }
                    break;
                }

                let durationForm = new AnnualWheelDurationFormModel(
                    getStartDate(props.selectedMonth, props.selectedYear),
                    activity?.notificationPeriodInDays ?? 14,
                    activity?.recurringIntervalInMonths,
                    activity!.type,
                    !!isEditActivity
                );

                let baseInformationForm = new AnnualWheelBaseInformationFormModel(
                    activity?.priority ?? AnnualWheelPriority.Moderate,
                    activity?.customId ?? "",
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    activity?.framework ?? undefined
                );

                setAnnualWheelDurationFormModel(durationForm);
                setAnnualWheelBaseInformationFormModel(baseInformationForm);
                setSelectedExistingActivity({ activityId: undefined, templateId: undefined });
                setShowFormError(false);
                setDialogStep(DialogStep.ActivityDurationData);
                break;
            }
            case DialogStep.CreateTemplate: {
                saveTemplate();
                break;
            }
            case DialogStep.CustomActivityEditFrom: {
                let isEditFormValid = isTemplateValid();
                if (isEditFormValid) {
                    setDialogStep(DialogStep.ActivityDurationData);
                }
                break;
            }
            case DialogStep.ActivityDurationData: {
                /* Assigning a local variable, because the setShowFormError will not change the validation before we reach the if/else */
                let isInvalid = annualWheelDurationFormModel!.isModelInvalid();
                setShowFormError(isInvalid);
                if (selectedActivity === AnnualWheelActivityType.ProcessingActivityValidation.toString() && isInvalid === false) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityBaseInformation);
                } else if (isInvalid === false) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityResponsibleData);
                }
                break;
            }
            case DialogStep.ActivityResponsibleData: {
                /* Assigning a local variable, because the setShowFormError will not change the validation before we reach the if/else */
                let isInvalid = annualWheelResponsibleFormModel!.IsModelInvalid();
                setShowFormError(isInvalid);
                if (isInvalid === false) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityBaseInformation);
                }

                break;
            }
            case DialogStep.ActivityBaseInformation: {
                if (!isNullOrWhitespace(selectedExistingActivity.templateId)) {
                    await updateActiveTemplate();
                }

                submitForm();
                break;
            }
        }
    }

    function submitForm() {
        if (selectedExistingActivity.activityId && !standardActivityCopy) updateActivity();
        else createActivity();
    }

    function getCategory(id: string) {
        var activityType = Number(id) as AnnualWheelActivityType;

        if (
            AnnualWheelActivityType.ProcessingActivityValidation === activityType ||
            AnnualWheelActivityType.ValidateRecord === activityType ||
            AnnualWheelActivityType.ValidateGroupEntityBasisForProcessing === activityType ||
            AnnualWheelActivityType.ReviewConsents === activityType ||
            AnnualWheelActivityType.SpotChecksOnConsents === activityType ||
            AnnualWheelActivityType.InspectThirdCountryTransfers === activityType
        ) {
            return AnnualWheelActivityCategoryType.ProcessingActivity;
        } else if (AnnualWheelActivityType.ReviewOfRiskAssessment === activityType || AnnualWheelActivityType.AssessImpactAssessmentsDPIA === activityType) {
            return AnnualWheelActivityCategoryType.Risk;
        } else if (
            AnnualWheelActivityType.DataProcessorAudit === activityType ||
            AnnualWheelActivityType.UpdateDataprocessorAgreements === activityType ||
            AnnualWheelActivityType.AuditSubProcessors === activityType
        ) {
            return AnnualWheelActivityCategoryType.DataProcessor;
        } else if (AnnualWheelActivityType.ReviewDeletionPolicy === activityType || AnnualWheelActivityType.CheckForDeFactoDeletion === activityType) {
            return AnnualWheelActivityCategoryType.DeletionPeriod;
        } else if (
            AnnualWheelActivityType.InspectProceduresAndPolicies === activityType ||
            AnnualWheelActivityType.UpdatePrivacyPolicyWebsite === activityType ||
            AnnualWheelActivityType.ReviewSocialMediaPrivacyPolicies === activityType ||
            AnnualWheelActivityType.CheckCookiePolicyAndBanner === activityType ||
            AnnualWheelActivityType.ReviewRequirementsForJobApplicants === activityType ||
            AnnualWheelActivityType.ReviewEmployeeDisclosure === activityType ||
            AnnualWheelActivityType.ReviewPolicyForDataSubjectsRights === activityType ||
            AnnualWheelActivityType.CheckPolicyForDataSubjectRights === activityType
        ) {
            return AnnualWheelActivityCategoryType.PolicyAndProcedure;
        } else if (
            AnnualWheelActivityType.CustomizeGDPRTemplates === activityType ||
            AnnualWheelActivityType.ExamineGuidlineDecisionsFromDDPA === activityType
        ) {
            return AnnualWheelActivityCategoryType.TemplateAndInstructions;
        } else if (AnnualWheelActivityType.FollowUpLastYearGDPRProjectsAndPlanNext === activityType) {
            return AnnualWheelActivityCategoryType.AnnualWheel;
        } else if (
            AnnualWheelActivityType.ValidateGDRPOnIntra === activityType ||
            AnnualWheelActivityType.PlanAwerenessCampaignOne === activityType ||
            AnnualWheelActivityType.PlanAwerenessCampaignTwo === activityType ||
            AnnualWheelActivityType.CompleteYearlyAudit === activityType ||
            AnnualWheelActivityType.ValidateYourGroupCompanies === activityType ||
            AnnualWheelActivityType.ReviewDisclosurRegForResignation === activityType
        ) {
            return AnnualWheelActivityCategoryType.Group;
        } else if (
            AnnualWheelActivityType.TestSecurityBreachProcedure === activityType ||
            AnnualWheelActivityType.CheckLogForSecurityBreaches === activityType ||
            AnnualWheelActivityType.TestAndFollowUpITContingencyPlan === activityType ||
            AnnualWheelActivityType.FollowUpAccessRights === activityType ||
            AnnualWheelActivityType.UpdateItSecurityPolicy === activityType
        ) {
            return AnnualWheelActivityCategoryType.ITAndSecurity;
        } else {
            return AnnualWheelActivityCategoryType.Other;
        }
    }

    function getDefaultDialogStep(activityId: string | undefined, templateId: string | undefined) {
        if (templateId && activityId) {
            return DialogStep.CustomActivityEditFrom;
        }

        if (activityId) {
            return DialogStep.ActivityDurationData;
        }

        return DialogStep.ActivitySelection;
    }

    function filterTemplatesByFilterOption(activtity: AnnualWheelActivityTemplateItem) {
        if (selectedFilterOption === AnnualWheelFilterOption.NotPlanned.toString()) {
            return !activtity.isScheduled;
        }

        if (selectedFilterOption === AnnualWheelFilterOption.Planned.toString()) {
            return activtity.isScheduled;
        }
        return true;
    }
}
