import { ProcessingActivityModel, ProcessingActivitySystem } from "../ProcessingActivity.types";
import { ValidationError } from "../../common/validationError";
import { useTranslation } from "../../localization/useTranslation";
import { useState } from "react";
import { useExpandedId } from "../../common/hooks/useExpandedId";
import { useMutation, useQueryClient } from "react-query";
import { deleteHttp, post, put } from "../../common/api/apiShared";
import { useSelectableDataCategoriesQuery } from "../hooks/useSelectableDataCategoriesQuery";
import { useGroupEntitiesQuery } from "../hooks/useGroupEntitiesQuery";
import { useSystemsQuery } from "../hooks/useSystemsQuery";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { ProcessingActivitySystemUpdateModel } from "./SystemsStep.types";
import { useSelectableLegalEntityQuery } from "../../legalEntity/useSelectableLegalEntityQuery";
import { useTransferBasisQuery } from "../hooks/useTransferBasisQuery";
import { useSelectableDocumentsForLegalEntitiesQuery } from "../hooks/useSelectableDocumentQuery";

export function useSystemStepDataMapping(processingActivity: ProcessingActivityModel) {
    const transferBasisQuery = useTransferBasisQuery(true);
    const optimisticUpdate = useOptimisticUpdate();
    const systemsQuery = useSystemsQuery();
    const { getExpandedId, setExpandedId } = useExpandedId();
    let documentsQuery = useSelectableDocumentsForLegalEntitiesQuery(
        true,
        systemsQuery.data
            ?.find((x) => x.id === getExpandedId())
            ?.subDataProcessors?.filter((s) => s.legalEntityId)
            .map((s) => s.legalEntityId!) ?? [],

        processingActivity.id
    );

    const legalEntitiesQuery = useSelectableLegalEntityQuery();
    const groupEntitiesQuery = useGroupEntitiesQuery();
    let dataCategoriesQuery = useSelectableDataCategoriesQuery();
    const queryClient = useQueryClient();
    const [isAddingNewSystem, setIsAddingNewSystem] = useState(false);
    let queryKey = "processingActivity" + processingActivity.id;

    let documentsLoading = documentsQuery.isLoading;
    let transferImpactAssessmentDocs = documentsQuery.transferImpactAssessmentDocs;

    const addSystemMutation = useMutation(addSystemApi, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
            setIsAddingNewSystem(false);
        },
    });

    const updateSystemMutation = useMutation(updateSystemApi);

    const removeSystemMutation = useMutation(removeSystemApi, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const handleSystemChange = async (id: string) => {
        if (isAddingNewSystem) {
            addSystemMutation.mutate({ processingActivityId: processingActivity.id, systemId: id });
        } else {
            const newPa = await optimisticUpdate.updateQueryData(queryKey, processingActivity, (pa) => {
                getCurrentSystem(pa, getExpandedId()).systemId = id;
            });

            updateBackend(newPa, id);
        }
        setExpandedId(id);
    };

    const handleAddSystem = () => {
        setIsAddingNewSystem(true);
        setExpandedId("");
    };

    const handleRemoveSystem = async (systemIndex: number, systemId: string) => {
        if (getExpandedId() === systemId) setExpandedId("");

        await optimisticUpdate.updateQueryData(queryKey, processingActivity, (pa) => {
            pa.systems = pa.systems.filter((s) => s.systemId !== systemId);
        });

        removeSystemMutation.mutate({
            processingActivityId: processingActivity.id,
            processingActivitySystemId: getProcessingActivitySystemId(systemId),
        });
    };

    const handleCollapseClick = (systemId: string) => {
        if (systemId) {
            if (getExpandedId() === systemId) setExpandedId("");
            else setExpandedId(systemId);
        }
    };

    const handleChosenDataCategoriesChanged = async (dataCategoryIds: Array<string>) => {
        await updateProcessingActivity((pa) => {
            getCurrentSystem(pa, getExpandedId()).dataCategoryIds = dataCategoryIds;
        });
    };

    const handleLegalEntitiesChange = async (legalEntityIds: Array<string>) => {
        await updateProcessingActivity((pa) => {
            getCurrentSystem(pa, getExpandedId()).legalEntityIds = legalEntityIds;
        });
    };

    const handeSubProcessorDataCategoryChange = async (systemSubDataProcessorId: string, dataCategoryIds: Array<string>) => {
        await updateProcessingActivity((pa) => {
            getCurrentSystem(pa, getExpandedId()).subDataProcessors.find((s) => s.systemSubDataProcessorId === systemSubDataProcessorId)!.dataCategoryIds =
                dataCategoryIds;
        });
    };

    const getProcessingActivitySystemId = (systemId: string) => {
        return processingActivity.systems.find((s) => s.systemId === systemId)!.processingActivitySystemId;
    };

    const getCurrentSystem = (processingActivity: ProcessingActivityModel, systemId: string) => {
        return processingActivity.systems.find((s) => s.systemId === systemId)!;
    };

    const updateProcessingActivity = async (updateCallback: (pa: ProcessingActivityModel) => void) => {
        const newPa = await optimisticUpdate.updateQueryData(queryKey, processingActivity, (pa) => {
            updateCallback(pa);
        });

        await updateBackend(newPa, getExpandedId());
    };

    const updateBackend = async (newPa: ProcessingActivityModel, systemId: string) => {
        await optimisticUpdate.updateWithProcessingActivityResponse(newPa, updateSystemMutation.mutateAsync, {
            processingActivityId: newPa.id,
            processingActivitySystemId: getProcessingActivitySystemId(systemId),
            updateModel: mapToUpdateModel(getCurrentSystem(newPa, systemId)),
        });
    };

    const mapToUpdateModel = (processingActivitySystem: ProcessingActivitySystem): ProcessingActivitySystemUpdateModel => {
        return {
            systemId: processingActivitySystem.systemId,
            dataCategories: processingActivitySystem.dataCategoryIds,
            groupEntities: processingActivitySystem.legalEntityIds,
            processingActivitySystemSubProcessorUpdateModel: processingActivitySystem.subDataProcessors.map((s) => {
                return {
                    systemSubDataProcessorId: s.systemSubDataProcessorId,
                    dataCategoryIds: s.dataCategoryIds,
                };
            }),
        };
    };

    const dataCategoriesData = dataCategoriesQuery.getDataCategoriesForProcessingActivity(processingActivity);
    let expandedSystem = processingActivity.systems.find((s) => s.systemId === getExpandedId());
    const chosenDataCategories = expandedSystem?.dataCategoryIds;
    const chosenLegalEntities = expandedSystem?.legalEntityIds ?? [];

    function onSystemCreated() {
        systemsQuery.refetch();
    }

    return {
        isAddingNewSystem,
        handleSystemChange,
        handleAddSystem,
        handleRemoveSystem,
        handleCollapseClick,
        getExpandedId,
        setIsAddingNewSystem,
        dataCategoriesData,
        isDataCategoriesLoading: dataCategoriesQuery.isLoading,
        chosenDataCategories,
        handleChosenDataCategoriesChanged,
        chosenLegalEntities,
        handleLegalEntitiesChange,
        isGroupEntitiesLoading: groupEntitiesQuery.isLoading,
        groupEntities: groupEntitiesQuery.allGroupEntities?.filter((g) => processingActivity.legalEntities.includes(g.id)),
        systemsLoading: systemsQuery.isLoading,
        systemsData: systemsQuery.data ?? [],
        handeSubProcessorDataCategoryChange,
        legalEntitiesData: legalEntitiesQuery.data ?? [],
        isLegalEntitiesLoading: legalEntitiesQuery.isLoading,
        transferBasisData: transferBasisQuery.data ?? [],
        isTransferBasisLoading: transferBasisQuery.isLoading,
        documentsLoading,
        transferImpactAssessmentDocs,
        onSystemCreated,
    };
}

export function useValidateSystemStep() {
    const { translateString } = useTranslation();

    return (model: ProcessingActivityModel) => {
        const result: Array<ValidationError> = [];

        if (!model.disableSystems && model.systems.length === 0) {
            result.push(new ValidationError("systems", translateString("systemsRequired")));
        }

        return result;
    };
}

async function addSystemApi(data: { processingActivityId: string; systemId: string }) {
    return await post<ProcessingActivityModel>("/processingactivity/" + data.processingActivityId + "/system/" + data.systemId, {});
}

async function removeSystemApi(data: { processingActivityId: string; processingActivitySystemId: string }) {
    return await deleteHttp("/processingactivity/" + data.processingActivityId + "/system/" + data.processingActivitySystemId);
}

async function updateSystemApi(data: { processingActivityId: string; processingActivitySystemId: string; updateModel: ProcessingActivitySystemUpdateModel }) {
    return await put("/processingactivity/" + data.processingActivityId + "/system/" + data.processingActivitySystemId, data.updateModel);
}
